<template>
  <div class="container">
      <div class="row">
          <div class="content">
              <page-header>ПОШИРЕНІ ЗАПИТАННЯ</page-header>
              <p><b>Скоро на сайті з’являться відповіді на найпоширеніші запитання</b></p>
          </div>
      </div>
  </div>
</template>

<script>
import PageHeader from "../components/commonComponents/PageHeader";
import { BadgerAccordion, BadgerAccordionItem } from "vue-badger-accordion";

export default {
components: {
  PageHeader,
  BadgerAccordion,
  BadgerAccordionItem,
},
};
</script>

<style scoped lang="scss">
h2 {
margin-bottom: 20px;
}

p {
span {
  font-weight: bold;
  margin-top: 20px;
}
}

.img-row {
display: flex;
align-items: center;

.img-row__img-wrap {
  width: 100%;
  max-width: 38px;
  margin: 0px, 0px, 0px, 0px;
  margin-right: 10px;
}
}

img {
float: left;
margin: 0px, 0px, 0px, 0px;
padding-right: 10px;
}

service {
display: block;
max-width: 100%;
height: auto;
}

h5 {
color: #b54285;
margin-bottom: 20px;
}

a {
color: #b54285;
}

.badger-accordion__panel {
max-height: 75vh;
overflow: hidden;
}

.badger-accordion__panel.-ba-is-hidden {
max-height: 0 !important;
}

.badger-accordion--initalised .badger-accordion__panel {
transition: max-height ease-in-out 0.2s;
}

.content_text {
color: #555555;
}

.img_ticket_shadow {
float: right;
margin: 0px, 0px, 0px, 0px;
padding-right: 10px;
box-shadow: 4px 4px 8px 1px rgba(35, 31, 32, 0.35);
}

.line {
border: none;
height: 2px;
color: #e7e7e7;
background-color: #434343;
}

.subline {
border: none;
height: 2px;
color: #e7e7e7;
background-color: #e7e7e7;
}

.text_bloc {
background-color: #f7f7f7;
}
</style>