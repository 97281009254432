import cardMixin from '../mixin/commonCardsLogic';
import { mapGetters } from "vuex";
export default {
  mixins: [cardMixin],
  methods: {
    enterEducationalOrganization(value) {
      //const type = this.$store.getters.getCurrentUniversityType(value);
      this.$store.commit('setStudentPrivilageDocumentType', '1'); // workaround
      this.user.educationUniversity = value;
    },
  },
  computed: {
    ...mapGetters({
      universitiesList: "universitiesList"
    }),
    studentPrivilegeDocument() {
      return this.studentPrivilegeDocumentType === '1' ? this.getStudentPrivilegeDocument : this.pupilPrivilegeDocument;
    },
    studentPrivilegeDocumentValue() {
      return this.studentPrivilegeDocument[0].id;
    },
  },
};
