function getApiUrl() {
  return process.env.VUE_APP_APIURL;
}
function getRootApiUrl() {
  return process.env.VUE_APP_API_BASE_URL;
}
function getCarrierApiUrl() {
  return getRootApiUrl() + "CarrierApi/";
}

function sendUserDataUrl() {
  return getApiUrl();
}

function getOrderByUidUrl(uid) {
  return getApiUrl() + uid;
}

function getUserPhotoForFailedPaymentUrl(uid) {
  return `${getApiUrl()}photo/${uid}`;
}

function getUserDataByKeyAndPinUrl({ key, pin }) {
  return `${getApiUrl()}key/${key}/${pin}/`;
}

function getPaymentUrl() {
  return `${getApiUrl()}payment/`;
}

function getVerifyCardByUserUrl(uid) {
  return `${getApiUrl()}VerifyCardByUser/${uid}`;
}

function getChangingPhotoUrl(uid) {
  return `${getApiUrl()}photo/${uid}`;
}
function getStreetsUrl() {
  return getApiUrl() + "streets";
}
function getCitiesUrl() {
  return getApiUrl() + "cities";
}
const urls = {
  getStreetsUrl,
  getCitiesUrl,
  sendUserDataUrl,
  getOrderByUidUrl,
  getUserPhotoForFailedPaymentUrl,
  getUserDataByKeyAndPinUrl,
  getVerifyCardByUserUrl,
  getPaymentUrl,
  getChangingPhotoUrl,
  getCarrierApiUrl,
};

export default urls;
