/*

1 Створено самостійно
2 Створено оператором
3 Перевірено самостійно
4 Потребує перевірки
45 Потребує повторної  перевірки
5 Корегування (Заміна фото)
6 Заяву підписано
7 Очікує відправки до друку
75 Очікує відправки до друку, Фото перевірено
8 Відправлено в друк
9 Картка очікує в офісі
10 Картка видана
11 Картка знищена
13 Оновлення інформації про пільгу
100 Відмінена з-за несплати
141	Очікує блокування
142	Заблокована
145	Очікує розблокування

* */

export const CREATED_BY_USER = 1;
export const CREATED_BY_OPERATOR = 2;
export const CONFIRMED_BY_USER = 3;
export const WAITING_FOR_APPROVAL = 4;
export const WAITING_FOR_2ND_APPROVAL = 45;
export const WAITING_FOR_PHOTO = 5;
export const ORDER_SIGNED = 6;
export const WAITING_FOR_EXPORT = 7;
export const PHOTO_CONFIRMED = 75;
export const CARD_PRINTING = 8;
export const CARD_READY = 9;
export const CARD_RECEIVED = 10;
export const CARD_DESTROYED = 11;
export const CARD_RFRESH_PRIVILEGE_INFO = 13;
export const CARD_CANCELED = 100;
export const CARD_WAITE_BLOCK = 141;
export const CARD_BLOCKED = 142;
export const CARD_WAITE_UNBLOCK = 145;
