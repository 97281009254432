<template>
  <div class="public-procurement">
    <div class="container">
      <div class="row">
        <div class="content">
          <page-header>Публічні закупівлі</page-header>
          <h2>
            Обґрунтування технічних та якісних характеристик предмета закупівлі,
            розміру бюджетного призначення, очікуваної вартості предмета
            закупівлі
          </h2>
        </div>

        <div class="content">
          <h3>2021</h3>
          <hr />

          <p class="download-icons">
            <a href="documents/UA-2021-03-26-002257-a.docx" download>
              UA-2021-03-26-002257-a.docx
            </a>
            <span class="download-icons__img-wrap">
              <a href="documents/UA-2021-03-26-002257-a.docx" download>
                <img :src="require(`@/assets/download.svg`)" />
              </a>
            </span>
          </p>
          <p class="download-icons">
            <a href="documents/UA-2021-11-09-004154-a.docx" download>
              UA-2021-11-09-004154-a.docx
            </a>
            <span class="download-icons__img-wrap">
              <a href="documents/UA-2021-11-09-004154-a.docx" download>
                <img :src="require(`@/assets/download.svg`)" />
              </a>
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "../commonComponents/PageHeader";

export default {
  components: {
    PageHeader,
  },
};
</script>

<style scoped lang="scss">
.normative-documents {
  width: 100%;
}

.container {
  /*background-color: #fff;*/
}

.normative-documents__header {
  width: 100%;
  text-align: left;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #c33d83;
  font-family: Century Gothic, Arial, sans-serif;
  text-transform: uppercase;
  padding-left: 20px;
}

.download-icons {
  display: flex;
  align-items: center;

  .download-icons__img-wrap {
    width: 100%;
    max-width: 24px;
    margin-left: 10px;
  }

  img {
    background-color: transparent;
    box-shadow: none;
    margin: 0;
  }

  a {
    color: #434343;
  }
  a:hover {
    color: #c33d83;
    text-decoration: none;
  }
}

h2 {
  font-size: 24px;
  padding-bottom: 2rem;
}

h3 {
  font-size: 24px;
  color: #c33d83;
}
</style>