export default {
  methods: {
    changeRoute(target) {
      if (target.dataset.url) {
        this.$router.push(target.dataset.url);
        if (window.innerWidth < 768) { this.$refs.toggleButton.click(); }
      }
    },
    openNewWindow() {
      window.open(process.env.VUE_APP_BASE_ROUTE, '_blank');
    }
  },
  computed: {
    currentRoute() {
      return this.$route.path;
    },
  },
};
