<template>
    <button>{{text}}</button>
</template>

<script>
export default {
  props: {
    text: String,

  },
};
</script>

<style scoped>

</style>
