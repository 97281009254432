<template>
    <div>отримання</div>
</template>

<script>
export default {

};
</script>

<style scoped lang="scss" src="./style.scss"></style>
