<template>
  <div class="container">
    <div class="row">
      <div class="content">
        <page-header>Як користуватись карткою</page-header>
        <ul>
          <li>
            <a href="#01"> Як здійснюється безготівкова оплата проїзду</a>
          </li>
          <li>
            <a href="#02"> Види муніципальних карток вінничанина (МКВ)</a>
          </li>
          <li>
            <a href="#03"> Отримання МКВ </a>
          </li>
          <li>
            <a href="#04">Продукти МКВ </a>
          </li>

          <li>
            <a href="#recharge_activation">Поповнення МКВ </a>
          </li>
          <li>
            <a href="#06">Інформація про стан продуктів на МКВ</a>
          </li>

          <li><a href="#07">Оплата/реєстрація проїзду</a></li>
          <li><a href="#08">Пересадка впродовж 30 хв. </a></li>
          <li>
            <a href="#09"> Як оплатити проїзд у маршрутних таксі Мерседес</a>
          </li>
          <li>
            <a href="#10"> Контроль оплати проїзду</a>
          </li>
           <li>
            <a href="#11"> Контроль оплати проїзду персоніфікованими МКВ</a>
          </li>
        </ul>
      </div>

      <div class="content">
        <div class="service">
          <a name="01"></a
          ><img
            src="../../assets/HowToUseCard/how_to_use_card_01.jpg"
            alt="Як здійснюється безготівкова оплата проїзду"
          />
        </div>
      </div>

      <div class="content">
        <div class="service">
          <a name="02"></a>

          <a href="/cards-description">
            <img
              src="../../assets/HowToUseCard/how_to_use_card_02.jpg"
              alt="Види муніципальних карток вінничанина (МКВ)"
          /></a>
        </div>
      </div>

      <div class="content">
        <div class="service">
          <a name="03"></a
          ><img
            src="../../assets/HowToUseCard/how_to_use_card_03.jpg"
            alt="Способи отримання муніципальних карток вінничанина"
          />
          <hr />
          <a href="/order-card"
            ><img
              src="../../assets/HowToUseCard/how_to_use_card_04.jpg"
              alt="Замовлення МКВ на сайті et.vn.ua"
          /></a>
        </div>
      </div>

      <div class="content">
        <div class="service">
          <a name="04"></a
          ><img
            src="../../assets/HowToUseCard/how_to_use_card_05.jpg"
            alt="Продукти муніципальних карток вінничанина"
          />
          <hr />
          <img
            src="../../assets/HowToUseCard/how_to_use_card_06.jpg"
            alt="Пільговий проїзний"
          />
        </div>
      </div>

      <div class="content">
        <div class="service">
          <a name="recharge_activation"></a
          ><img
            src="../../assets/HowToUseCard/how_to_use_card_07.jpg"
            alt="Способи поповнення МКВ"
          />
          <hr />
          <a name="recharge_activation_val"></a
          ><img
            src="../../assets/HowToUseCard/how_to_use_card_08.jpg"
            alt="Як активізувати онлайн поповнення на МКВ на валідаторі у транспорті"
          />
          <hr />
          <a href="https://pay.vn.ua/card_service"
            ><img
              src="../../assets/HowToUseCard/how_to_use_card_09.jpg"
              alt="Як здійснити онлайн поповнення на сайті et.vn.ua"
          /></a>
        </div>
      </div>

      <div class="content">
        <div class="service">
          <a name="06"></a
          ><img
            src="../../assets/HowToUseCard/how_to_use_card_10.jpg"
            alt="Як отримати інформацію про стан продуктів на МКВ"
          />

          <hr />
          <a name="Info"></a
          ><img
            src="../../assets/HowToUseCard/how_to_use_card_11.jpg"
            alt="Вікно Інформація для різних типів продуктів МКВ"
          />
        </div>
      </div>

      <div class="content">
        <div class="service">
          <a name="07"></a
          ><img
            src="../../assets/HowToUseCard/how_to_use_card_12.jpg"
            alt="Як оплатити/зареєструвати проїзд МКВ або банківською карткою"
          />

          <hr />
          <img
            src="../../assets/HowToUseCard/how_to_use_card_13.jpg"
            alt="Інформація на екранах валідатора при успішній оплаті/реєстрації проїзду для різних продуктів або банківською карткою"
          />
        </div>
      </div>

      <div class="content">
        <div class="service">
          <img
            src="../../assets/HowToUseCard/how_to_use_card_14.jpg"
            alt="Як оплатити провезення багажу"
          />

          <hr />
          <img
            src="../../assets/HowToUseCard/how_to_use_card_15.jpg"
            alt="Як оплатити проїзд ще одного пасажира"
          />
        </div>
      </div>

      <div class="content">
        <div class="service">
          <a name="08"></a
          ><img
            src="../../assets/HowToUseCard/how_to_use_card_16.jpg"
            alt="Правила пересадки впродовж 30 хв."
          />
        </div>
      </div>

      <div class="content">
        <div class="service">
          <a name="09"></a>
          <img
            src="../../assets/HowToUseCard/how_to_use_card_17.jpg"
            alt="Як опдатити проїзд у маршрутних таксі Мерседес"
          />
        </div>
      </div>

      <div class="content">
        <div class="service">
          <a name="10"></a
          ><img
            src="../../assets/HowToUseCard/how_to_use_card_18.jpg"
            alt="Як здійснюється перевірка оплати проїзду"
          />
        </div>
      </div>

      <div class="content">
        <div class="service">
          <a name="11"></a
          ><img
            src="../../assets/HowToUseCard/how_to_use_card_19.jpg"
            alt="Як здійснюється перевірка оплати проїзду"
          />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import PageHeader from "../commonComponents/PageHeader";

export default {
  components: {
    PageHeader,
  },
};
</script>

<style scoped lang="scss" src="./style.scss"></style>





