<template>
  <div class="person-address">
    <base-select
      text="Область"
      reference="region"
      @input="inputRegion"
      v-model="user.region"
      :list="regionsList"
    ></base-select>

    <input type="checkbox" id="isManualCityInput" v-model="isManualCityInput" />
    <label class="pl-1" for="isManualCityInput"> Ручне введення</label>

    <base-select
      v-if="!isManualCityInput && renderComponent"
      v-model="user.cityId"
      @input="inputCity"
      text="Населений пункт"
      reference="registrationSelect"
      :list="citiesList"
    ></base-select>

    <base-input
      v-else
      @input="delete user.cityId"
      v-model="user.cityTown"
      text="Населений пункт"
      reference="registration"
      :validation="{ required: true, max: 50, regex: /^[а-яієїґ .\']+$/i }"
    >
    </base-input>

    <input
      type="checkbox"
      id="isManualStreetinput"
      v-model="isManualStreetinput"
    />
    <label class="pl-1" for="isManualStreetinput"> Ручне введення</label>

    <base-select
      v-if="!isManualStreetinput && renderComponent"
      v-model="user.streetId"
      @input="inputStreet"
      text="Вулиця (провулок, проспект, проїзд)"
      reference="streetSelect"
      :list="streetsList"
    ></base-select>

    <base-input
      v-else
      @input="delete user.streetId"
      v-model="user.street"
      reference="street"
      text="Вулиця (провулок, проспект, проїзд)"
      :validation="{ required: true, max: 50, regex: /^[а-яієїґ\- .0-9\']+$/i }"
    >
    </base-input>
    <base-input
      text="Номер будинку та корпус, якщо він є"
      reference="buildingNumber"
      v-model="user.houseNumber"
      :validation="{ required: true, regex: /^[0-9]+[а-яієїґ ()0-9/\\]{0,}$/i }"
    >
    </base-input>
    <base-input
      text="Номер квартири"
      reference="flatNumber"
      v-model="user.apartment"
      :validation="{ regex: /^[0-9/, ]+[а-яієїґ]?$/i }"
    >
    </base-input>
  </div>
</template>

<script>
import GetUserMixin from "../../mixin/GetUserMixin";
import BaseInput from "../../BaseComponents/BaseInput";
import BaseSelect from "../../BaseComponents/BaseSelect";
import { mapActions, mapGetters } from "vuex";

export default {
  mixins: [GetUserMixin],
  inject: ["$validator"],
  components: {
    BaseInput,
    BaseSelect,
  },
  data() {
    return {
      renderComponent: true,
      isManualCityInput: false,
      isManualStreetinput: false,
    };
  },
  methods: {
    ...mapActions({
      getCitiesByRegion: "getCitiesByRegion",
      getStreetsByCity: "getStreetsByCity",
    }),
    async inputRegion(id) {
      await this.getCitiesByRegion(id);
      if (this.user.cityId) {
        delete this.user.cityId;
      }
      if (this.user.streetId) {
        delete this.user.streetId;
      }

      this.renderFix();
    },
    async inputCity(cityId) {
      await this.getStreetsByCity(cityId);
      this.user.cityTown = this.citiesList.find((x) => x.id == cityId)?.text;
      if (this.user.streetId) {
        delete this.user.streetId;
      }
      this.renderFix();
    },
    renderFix() {
      this.renderComponent = false;
      this.$nextTick().then(() => {
        this.renderComponent = true;
      });
    },
    inputStreet(streetId) {
      this.user.street = this.streetsList.find((x) => x.id == streetId)?.text;
      this.renderFix();
    },
  },
  computed: {
    ...mapGetters({
      regionsList: "regionsList",
      citiesList: "getCities",
      streetsList: "getStreets",
    }),
  },
};
</script>

<style scoped>
.person-address {
  width: 100%;
}
</style>
