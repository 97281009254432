<template>
  <div class="container-fluid footer">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <div class="footer_logo">
            <a href="/"><img src="../../../assets/logo.png" /></a>
          </div>
          <!-- <div class="social">
                        <p>Ми у соцмережах: <a href="https://www.facebook.com/VinnytsiaMKV/" class="facebook-icon"><span class="icon-facebook-letter"></span></a></p>
                    </div>-->
          <ul>
            <li>
              <a
                href="https://www.facebook.com/VinnytsiaMKV/"
                class="facebook-icon"
                >Ми у соцмережах<span class="icon-facebook-letter"></span
              ></a>
            </li>

            <li>
              <a href="https://t.me/VinnytsiaMKV">
                Чат техпідтримки <img src="../../../assets/Telegramm.png"
              /></a>
            </li>

            <li>
              <span class="icon-email-address"></span
              ><!--<a href="https://et.vn.ua">-->mcv@et.vn.ua
            </li>
          </ul>
        </div>

        <div class="col-md-4">
          <h5>Контакти</h5>
          <ul>
            <!--<li class="li-bold"><span class="icon-address"></span>Адреса:</li>-->

            <li><span class="icon-address"></span> м.Вінниця,</li>
            <li><span class="li-mg">вул. Соборна, 36</span></li>
            <!--<li class="li-bold li-mg">Телефони:</li>-->
            <li><span class="icon-telephone-receiver"></span>(0800)330-155</li>
            <li><span class="li-mg"></span>(0432)655-155</li>
            <!--<li><span class="icon-mobile-phone"></span>(063)555-66-77</li>-->
          </ul>
        </div>
        <div class="col-md-4">
          <h5>"Е-квиток Вінниці":</h5>
          <ul>
            <li>
              <div class="mobile_app">
                <a href="https://play.google.com/store/apps/details?id=ua.vn.et">
                  <img
                    class="buttons_img"
                    :src="
                      require(`@/assets/IconMobilApps/get-it-on-google-play.svg`)
                    "
                  />
                </a>
              </div>
            </li>
             <!--
            <li>
              <div class="mobile_app">
                <a href="https://apps.apple.com/us/app/id1509436565">
                  <img
                    class="buttons_img"
                    :src="
                      require(`@/assets/IconMobilApps/available-on-the-app-store.svg`)
                    "
                  />
                </a>
              </div>
            </li>
          -->
            <li>
              <div class="mobile_app">
                <a href="https://card-holder.et.vn.ua/#/login-page">
                  <img
                    class="buttons_img"
                    :src="
                      require(`@/assets/IconMobilApps/get-it-on-the-desktop.svg`)
                    "
                  />
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped src="./styles.scss" lang="scss"></style>
