<template>
  <div class="user-authorization">
    <default-window @close="loginCarrier">
      <template v-slot:icon>
        <img :src="require(`@/assets/Man.svg`)" alt="check status" />
      </template>
      <template v-slot:header>Увійти в кабінет перевізника</template>
      <template>
        <form action="" @submit.prevent="loginCarrier">
          <base-input
            reference="login"
            v-model="login"
            text="Логін"
            :validation="{ required: true }"
          ></base-input>
          <base-input
            v-model="password"
            text="Пароль"
            reference="password"
            :validation="{ required: true }"
          ></base-input>
        </form>
      </template>
    </default-window>
  </div>
</template>
<script>
import BaseInput from "../BaseInput";
import DefaultWindow from "../DefaultWindow";
export default {
  components: { BaseInput, DefaultWindow },
  name: "CarrierCabinet",
  data() {
    return {
      login: "",
      password: "",
    };
  },
  methods: {
    async loginCarrier() {
      let result = await this.$store.dispatch("carrierLogin", {
        Login: this.login,
        Password: this.password,
      });
      if (result) {
        this.$router.push("/popa");
      } else {
        this.$store.commit("toggleErrorLoginCarrierWindow", true);
      }
    },
  },
};
</script>
<style>
</style>