<template>
  <div>
    <p class="document-format">
      {{ docName }}:
      {{ serias ? `серія ${serias}, ` : "" }}
      {{ docNumber ? "номер " + docNumber + ", " : "" }}
      {{ cardType != 4 ? validTill : "" }}
    </p>
    <p v-if="cardType == 4">
      Пільга дійсна до: {{ validTill || "Безстрокова" }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    serias: {
      required: true,
    },
    docNumber: {
      required: true,
    },
    docName: {
      required: true,
    },
    validTill: {
      required: true,
    },
    cardType: {
      required: false,
    },
  },
};
</script>

<style scoped lang="scss"></style>
