<template>
  <div class="privilege-doc">
    <base-select
      reference="privilegeDocumentTypePup"
      :list="documentsList"
      text=""
      :value="documentsValue"
      :disabled="true"
    >
    </base-select>
    <base-input
      :value="user.privilegeDocumentSeries"
      @input="transformSeries('privilegeDocumentSeries', $event)"
      reference="privilegeDocumentSeriasPup"
      text="Серія"
      :validation="seriasValidation"
      :customErrorMessage="documentSeriesErrorMessage"
      :hint="documentSeriesHint"
    >
    </base-input>
    <base-input
      v-model="user.privilegeDocumentNumber"
      reference="privilegeDocumentNumberPupInp"
      text="Номер"
      :validation="numberValidation"
      :customErrorMessage="documentNumberErrorMessage"
      :hint="documentNumberHint"
    >
    </base-input>
    <date-picker
      v-model="user.privilegeDocumentValidTill"
      reference="privilegeDocumentTerm"
      text="Термін дії документу"
      :validation="{ required: validTillRegexString == 'required' }"
    >
    </date-picker>
  </div>
</template>

<script>
import GetUserMixin from "../../mixin/GetUserMixin";
import BaseInput from "../../BaseComponents/BaseInput";
import BaseSelect from "../../BaseComponents/BaseSelect";
import DatePicker from "../../BaseComponents/DatePicker";
import { mapGetters } from "vuex";

export default {
  mixins: [GetUserMixin],
  inject: ["$validator"],
  components: {
    BaseInput,
    BaseSelect,
    DatePicker,
  },
  props: {
    student: {
      required: true,
      type: Boolean,
    },
    documentsList: {
      required: true,
      type: Array,
    },
    documentsValue: {
      required: true,
    },
  },
  computed: {
    ...mapGetters({ privilegeDocumentTypes: "privilegeDocumentTypes" }),
    documentNumberErrorMessage() {
      return this.privilegeDocumentTypeObject
        ? this.privilegeDocumentTypeObject.documentNumberError
        : "";
    },
    documentSeriesErrorMessage() {
      return this.privilegeDocumentTypeObject
        ? this.privilegeDocumentTypeObject.documentSeriesError
        : "";
    },
    documentNumberHint() {
      return this.privilegeDocumentTypeObject
        ? this.privilegeDocumentTypeObject.documentNumberHint
        : "";
    },
    documentSeriesHint() {
      return this.privilegeDocumentTypeObject
        ? this.privilegeDocumentTypeObject.documentSeriesHint
        : "";
    },
    privilegeDocumentTypeObject() {
      if (
        this.user.privilegeDocumentType &&
        this.privilegeDocumentTypes.length
      ) {
        let documentId = this.documentsValue;
        return this.privilegeDocumentTypes.find(function (el) {
          return el.id == documentId;
        });
      } else {
        return null;
      }
    },
    seriesRegexString() {
      if (this.privilegeDocumentTypeObject) {
        return this.privilegeDocumentTypeObject.seriesRule;
      } else {
        return "^$";
      }
    },
    numberRegexString() {
      if (this.privilegeDocumentTypeObject) {
        return this.privilegeDocumentTypeObject.numberRule;
      } else {
        return "^$";
      }
    },
    validTillRegexString() {
      if (this.privilegeDocumentTypeObject) {
        return this.privilegeDocumentTypeObject.validTillRule;
      } else {
        return "required"; // not required // indefinitely
      }
    },

    seriasValidation() {
      return { regex: new RegExp(this.seriesRegexString) };
    },
    numberValidation() {
      return { regex: new RegExp(this.numberRegexString) };
    },
  },
  created() {
    this.user.privilegeDocumentType = this.documentsValue;
    console.log(this.user.privilegeDocumentType);
  },
  updated() {
    this.user.privilegeDocumentType = this.documentsValue;
    console.log(this.user.privilegeDocumentType);
  },
};
</script>

<style scoped>
.privilege-doc {
  width: 100%;
}
</style>
