<template>
    <div class="container">
        <div class="row">
            <div class="content">
                <page-header>Про нас</page-header>

                <p>Комунальне підприємство Вінницької міської ради "Вінницький інформаційний центр" реалізує проект впровадження у місті Вінниці автоматизованої системи обліку оплати проїзду в громадському транспорті та системи автоматичного визначення місцезнаходження транспортних засобів (АВМТЗ), яка буде відслідковувати пересування транспортних засобів, надавати інформацію про графік робочого часу водія та проходження кілометражу
                    транспорту. Підприємство забезпечює користувачів засобами оплати проїзду - <a href="cards-description">муніципальними картками вінничанина</a>.
                </p>

                </div>

               <!-- <div class="content">
                <h2>Активація онлайн поповнення на муніципальну картку вінничанина</h2>
                
                <div class='image'>
                    <img src="../../assets/activation_online_replenishment.gif">
                    <p></p>
                    </div>  
                <p>Шановні вінничани та гості міста, звертаємо вашу увагу на те, що процес <b><a href="/card-howto#05">поповнення картки </a>засобами онлайн-сервісів </b>складається із двох обов'язкових етапів: 
                    <ol><b>
                    <li>Oнлайн-поповнення.
                    </li>
                    <li>Подальшої активації онлайн-поповнення (запис коштів) безпосередньо на вашу картку.
                    </li>
                    </b>
                    </ol>
                </p>    
                <p><b><a href="/card-howto#05-1">Активація онлайн поповнення</a></b> здійснюється так:                 
                                <ol>
                    <li> Не раніше, ніж 30 хвилин після онлайн поповнення, на борту транспорту. <a href="/card-howto#05-2">Для активації на валідаторі </a> натисніть кнопку Інфо, прикладіть картку та утримуйте її до завершення активації. У вікні Інфо, що з'явиться автоматично після завершення поповнення, відобразиться актуальна сума. 
                    </li>
                    </ol>
               </p>
                <p>Із повним <a href="/card-howto">керівництвом користувача</a> ознайомтесь на сайті.
                </p>
            </div>
            
            
    <div class="content">
                <h2>Оплата/реєстрація проїзду на валідаторі</h2>
                
                <div class='image'>
                    <img src="../../assets/pay.gif">
                    <p></p>
                    </div>  
                <p>Шановні вінничани та гості міста, нагадуємо, що для оплати проїзду ЧЕРЕЗ ВАЛІДАТОР картку необхідно <b>підносити до зеленої позначки із «вушками» та зачекати до появи підтвердження у формі зеленого прапорця у правому куті валідатора.</b> 
                </p>
                <p>
                    <b>Будь ласка, зверніть увагу</b> - у громадському транспорті міста розміщено плакати із інструкцією здійснення оплати проїзду, <b>ознайомтесь із нею та повторюйте все так, як на зображенні.</b> 
               </p>

                <p>За останній тиждень до нас надійшли звернення стосовного того, що картки не працюють й валідатор видає помилку "НЕВІДПОВІДНА КАРТКА", усі вони були перевірені й виявилось, що власники карток неправильно ними користуються й прикладають картку не до відповідної позначки, а до дисплею, або ж надто швидко забирають картку з поля сканування. Такі дії призводять до того що система видає помилку, а з часом просто блокує картки без можливості відновлення.</p>
                <p>Із повним <a href="/card-howto">керівництвом користувача</a> також можна ознайомитись на сайті.
                </p>
            </div>
        -->

            <news-component></news-component>
        </div>
    </div>
</template>
<script>
import newsComponent from './newsComponent';
import PageHeader from '../commonComponents/PageHeader';

export default {
  components: {
    newsComponent,
    PageHeader,
  },
};
</script>

<style scoped src="./style.scss" lang="scss"></style>
