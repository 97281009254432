var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"content"},[_c('page-header',[_vm._v("Як користуватись карткою")]),_vm._m(0)],1),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('a',{attrs:{"href":"#01"}},[_vm._v(" Як здійснюється безготівкова оплата проїзду")])]),_c('li',[_c('a',{attrs:{"href":"#02"}},[_vm._v(" Види муніципальних карток вінничанина (МКВ)")])]),_c('li',[_c('a',{attrs:{"href":"#03"}},[_vm._v(" Отримання МКВ ")])]),_c('li',[_c('a',{attrs:{"href":"#04"}},[_vm._v("Продукти МКВ ")])]),_c('li',[_c('a',{attrs:{"href":"#recharge_activation"}},[_vm._v("Поповнення МКВ ")])]),_c('li',[_c('a',{attrs:{"href":"#06"}},[_vm._v("Інформація про стан продуктів на МКВ")])]),_c('li',[_c('a',{attrs:{"href":"#07"}},[_vm._v("Оплата/реєстрація проїзду")])]),_c('li',[_c('a',{attrs:{"href":"#08"}},[_vm._v("Пересадка впродовж 30 хв. ")])]),_c('li',[_c('a',{attrs:{"href":"#09"}},[_vm._v(" Як оплатити проїзд у маршрутних таксі Мерседес")])]),_c('li',[_c('a',{attrs:{"href":"#10"}},[_vm._v(" Контроль оплати проїзду")])]),_c('li',[_c('a',{attrs:{"href":"#11"}},[_vm._v(" Контроль оплати проїзду персоніфікованими МКВ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"service"},[_c('a',{attrs:{"name":"01"}}),_c('img',{attrs:{"src":require("../../assets/HowToUseCard/how_to_use_card_01.jpg"),"alt":"Як здійснюється безготівкова оплата проїзду"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"service"},[_c('a',{attrs:{"name":"02"}}),_c('a',{attrs:{"href":"/cards-description"}},[_c('img',{attrs:{"src":require("../../assets/HowToUseCard/how_to_use_card_02.jpg"),"alt":"Види муніципальних карток вінничанина (МКВ)"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"service"},[_c('a',{attrs:{"name":"03"}}),_c('img',{attrs:{"src":require("../../assets/HowToUseCard/how_to_use_card_03.jpg"),"alt":"Способи отримання муніципальних карток вінничанина"}}),_c('hr'),_c('a',{attrs:{"href":"/order-card"}},[_c('img',{attrs:{"src":require("../../assets/HowToUseCard/how_to_use_card_04.jpg"),"alt":"Замовлення МКВ на сайті et.vn.ua"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"service"},[_c('a',{attrs:{"name":"04"}}),_c('img',{attrs:{"src":require("../../assets/HowToUseCard/how_to_use_card_05.jpg"),"alt":"Продукти муніципальних карток вінничанина"}}),_c('hr'),_c('img',{attrs:{"src":require("../../assets/HowToUseCard/how_to_use_card_06.jpg"),"alt":"Пільговий проїзний"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"service"},[_c('a',{attrs:{"name":"recharge_activation"}}),_c('img',{attrs:{"src":require("../../assets/HowToUseCard/how_to_use_card_07.jpg"),"alt":"Способи поповнення МКВ"}}),_c('hr'),_c('a',{attrs:{"name":"recharge_activation_val"}}),_c('img',{attrs:{"src":require("../../assets/HowToUseCard/how_to_use_card_08.jpg"),"alt":"Як активізувати онлайн поповнення на МКВ на валідаторі у транспорті"}}),_c('hr'),_c('a',{attrs:{"href":"https://pay.vn.ua/card_service"}},[_c('img',{attrs:{"src":require("../../assets/HowToUseCard/how_to_use_card_09.jpg"),"alt":"Як здійснити онлайн поповнення на сайті et.vn.ua"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"service"},[_c('a',{attrs:{"name":"06"}}),_c('img',{attrs:{"src":require("../../assets/HowToUseCard/how_to_use_card_10.jpg"),"alt":"Як отримати інформацію про стан продуктів на МКВ"}}),_c('hr'),_c('a',{attrs:{"name":"Info"}}),_c('img',{attrs:{"src":require("../../assets/HowToUseCard/how_to_use_card_11.jpg"),"alt":"Вікно Інформація для різних типів продуктів МКВ"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"service"},[_c('a',{attrs:{"name":"07"}}),_c('img',{attrs:{"src":require("../../assets/HowToUseCard/how_to_use_card_12.jpg"),"alt":"Як оплатити/зареєструвати проїзд МКВ або банківською карткою"}}),_c('hr'),_c('img',{attrs:{"src":require("../../assets/HowToUseCard/how_to_use_card_13.jpg"),"alt":"Інформація на екранах валідатора при успішній оплаті/реєстрації проїзду для різних продуктів або банківською карткою"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"service"},[_c('img',{attrs:{"src":require("../../assets/HowToUseCard/how_to_use_card_14.jpg"),"alt":"Як оплатити провезення багажу"}}),_c('hr'),_c('img',{attrs:{"src":require("../../assets/HowToUseCard/how_to_use_card_15.jpg"),"alt":"Як оплатити проїзд ще одного пасажира"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"service"},[_c('a',{attrs:{"name":"08"}}),_c('img',{attrs:{"src":require("../../assets/HowToUseCard/how_to_use_card_16.jpg"),"alt":"Правила пересадки впродовж 30 хв."}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"service"},[_c('a',{attrs:{"name":"09"}}),_c('img',{attrs:{"src":require("../../assets/HowToUseCard/how_to_use_card_17.jpg"),"alt":"Як опдатити проїзд у маршрутних таксі Мерседес"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"service"},[_c('a',{attrs:{"name":"10"}}),_c('img',{attrs:{"src":require("../../assets/HowToUseCard/how_to_use_card_18.jpg"),"alt":"Як здійснюється перевірка оплати проїзду"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"service"},[_c('a',{attrs:{"name":"11"}}),_c('img',{attrs:{"src":require("../../assets/HowToUseCard/how_to_use_card_19.jpg"),"alt":"Як здійснюється перевірка оплати проїзду"}})])])
}]

export { render, staticRenderFns }