import axios from 'axios';
import urlMapping from '../../urlMapping';
import router from '../../../router';

export default {
  state: {
    carrierAccessToken: '',
  },
  getters: {
    getCarrierAccessToken(state) {
      return state.carrierAccessToken
    }
  },
  mutations: {
    setCarrierAccessToken(state, token) {
      state.carrierAccessToken = token;
    }
  },
  actions: {
    async carrierLogin(context, param) {
      try {
        const url = urlMapping.getCarrierApiUrl();
        let result = await axios.post(url, param, {
          headers: {
            'Content-Type': 'application/json',
          }
        });
        let token = result.data.token;
        context.commit('setCarrierAccessToken', token);
        return true;
      }
      catch (error) {
        return false;
      }
    },
    async getCarrierInfo(context) {
      try {
        const url = urlMapping.getCarrierApiUrl();
        let result = await axios.get(url, {
          headers: {
            'Authorization': 'Bearer ' + context.getters.getCarrierAccessToken,
            'Content-Type': 'application/json',
          }
        });
        return result;
      }
      catch (error) {
        console.error(error);
        return error;
      }
    },

  }
};
