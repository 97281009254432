import { mapGetters } from 'vuex';

export default {
  computed: {
    curStep: {
      get() {
        return this.$store.getters.getQueueCurStep;
      },
      set(value) {
        this.$store.state.queue.curStep = value;
      },
    },
    ...mapGetters({
      steps: 'getQueueSteps',
    }),
  },

};
