<template>
  <div id="app">
    <!--<button id="show-modal" @click="$store.state.isShowErrorModal = true">Show Modal</button>-->
    <!-- use the modal component, pass in the prop -->
    <default-modal
      v-if="$store.getters.isErrorWindowShown"
      @close="$store.commit('toggleErrorWindow')"
    >
      <!--
              you can use custom content here to overwrite
              default content
      -->
      <!--<h3 slot="header">Щось пішло не так</h3>-->

      <div slot="body">
        <p>Помилка на сервері - спробуйте пізніше</p>
      </div>
    </default-modal>
  </div>
</template>

<script>
import DefaultModal from './DefaultModal';

export default {
  components: {
    DefaultModal,
  },
};
</script>

<style scoped lang="scss">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
  display: none;
}

.modal-body {
  margin: 20px 0;

  p {
    font-size: 16px;
    color: #434343;
  }
}

.modal-default-button {
  float: right;
}

/*
     * The following styles are auto-applied to elements with
     * transition="modal" when their visibility is toggled
     * by Vue.js.
     *
     * You can easily play with the modal transition by editing
     * these styles.
     */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
