import axios from "axios";
import urlMapping from "../../urlMapping";

export default {
  state: {
    cities: [],
  },
  getters: {
    getCities(state) {
      return state.cities;
    },
  },
  mutations: {
    setCities(state, payload) {
      state.cities = payload;
    },
  },
  actions: {
    async getCitiesByRegion(context, regionId) {
      try {
        const url = urlMapping.getCitiesUrl();
        let result = await axios.get(url, {
          params: { regionId },
          headers: {
            "Content-Type": "application/json",
          },
        });
        context.commit("setCities", result.data);
      } catch (error) {
        console.error(error);
        return error;
      }
    },
  },
};
