import axios from "axios";
import urlMapping from "../../urlMapping";

export default {
  state: {
    streets: [],
  },
  getters: {
    getStreets(state) {
      return state.streets;
    },
  },
  mutations: {
    setStreets(state, payload) {
      state.streets = payload;
    },
  },
  actions: {
    async getStreetsByCity(context, cityId) {
      try {
        const url = urlMapping.getStreetsUrl();
        let result = await axios.get(url, {
          params: { cityId },
          headers: {
            "Content-Type": "application/json",
          },
        });
        context.commit("setStreets", result.data);
      } catch (error) {
        console.error(error);
        return error;
      }
    },
  },
};
