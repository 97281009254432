import { mapGetters, mapActions } from 'vuex';
import VueRecaptcha from 'vue-recaptcha';

import QRCode from 'qrcode';
import moment from 'moment';

import { paymentStatuses, orderStatuses } from '../../config';

import BaseButton from '../BaseButton';
import ConfirmPayment from '../Payment/ConfirmPayment';
import ImageClip from '../commonComponents/ImageClip';

import CardStatus from './CardStatus';

export default {
  data() {
    return {
      currentStep: 1,
      firstStep: 1,
      secondStep: 2,
      thirdStep: 3,
      personImage: null,
      qrCode: null,
      isShownConfirmPayment: false,
      disablePayButton: false,
      fileValidation: {
        isValidFile: false,
        isDirtyFile: false,
      },
      orderStatuses: {},
      paymentStatuses: {},
      sitekey: process.env.VUE_APP_RECAPTCHA_KEY,
      newPersonPhoto: null
    };
  },
  components: {
    BaseButton,
    CardStatus,
    ConfirmPayment,
    ImageClip,
    VueRecaptcha,
  },
  methods: {
    ...mapActions([
      'changeUserPhoto',
      'getUserDataByUid'
    ]),
    setCurrentStep() {
      if (this.user.paymentStatusId === paymentStatuses.SUCCESSFUL_PAYMENT) {
        this.currentStep = 3;
      } else if (
        this.user.orderStatusId === orderStatuses.CREATED_BY_USER ||
        this.user.orderStatusId === orderStatuses.CREATED_BY_OPERATOR
      ) {
        this.currentStep = 1;
      } else {
        this.currentStep = 2;
      }
    },
    hideConfirmPayment() {
      this.isShownConfirmPayment = false;
    },
    showConfirmPayment() {
      this.isShownConfirmPayment = true;
    },
    async checkCard() {
      this.isShownConfirmPayment = false;

      const successfulPush = this.$store.dispatch('verifyCardByUser', this.user.orderUid);

      console.log(successfulPush);

      if (successfulPush) {
        this.currentStep = 2;
      }
    },
    payForCard() {
      this.goToPayment();
      // this.currentStep = 3;
    },
    async goToPayment() {
      this.disablePayButton = true;

      this.$store.commit('toggleLoadingIndicator');

      const payload = {
        uid: this.user.orderUid,
        key: this.user.orderKey,
        pin: this.user.orderPin,
      };
      this.$store.dispatch('getPaymentUrl', payload)
        .then((url) => {
          if (url) {
            window.location.href = url;
          }
        })
        .catch(() => {
          this.disablePayButton = false;
        });
    },
    getFormatedDate(value) {
      console.log(value);
      const date = moment(value);
      const day = date.date();
      const month = date.month();
      const year = date.year();
      return `${this.formatDateView(day)}.${this.formatDateView(month + 1)}.${year}`;
    },
    formatDateView(date) {
      return (`0${date}`).slice(-2);
    },
    async generateQR(text) {
      try {
        const url = await QRCode.toDataURL(text);
        this.qrCode = url;
        console.log(url);
      } catch (err) {
        console.error(err);
      }
    },
    async initAvatar(uid) {
      try {
        const img = await this.$store.dispatch('getUserPhotoForFailedPayment', uid);
        const urlCreator = window.URL || window.webkitURL;
        this.personImage = urlCreator.createObjectURL(img);
      } catch (e) {
        console.warn(e);
      }
    },

    printQuestionnaire() {
      window.print();
    },
    formatDate(date) {
      return `${moment(date).format('DD.MM.YY')} ${moment(date).format('HH:mm')}`;
    },
    async savePersonImage(recaptchaToken) {
      const uid = this.user.orderUid;
      const payload = {
        personPhoto: this.newPersonPhoto,
        uid,
        recaptchaToken
      };

      const result = await this.changeUserPhoto(payload);

      if (result) {
        this.getUserDataByUid(uid);
        this.initAvatar(uid);
      }
    },
    onCaptchaExpired() {
      console.log('Captcha Expired');
      this.$refs.recaptcha.reset();
    },
    verifyRecaptcha(newPersonPhoto) {
      this.newPersonPhoto = newPersonPhoto;
      this.$refs.recaptcha.execute();
    }
  },
  computed: {
    ...mapGetters([
      'getUserGender',
      'getCardType',
      'getOrderUid',
      'femaleGender',
      'getStudentCardID',
      'getPupilCardID',
      'getMunicipalCardID',
      // 'getOrderId',

    ]),
    ...mapGetters({
      user: 'getConfirmPaymentUserData',
      paymentsList: 'getPaymentList',
      statusesList: 'getStatusesList',
    }),
    isFreeCard() {
      return this.user.paymentTypeId === 0
    },
    isReissueCard() {
      this.cardResonId != 1
    },
    personAppeal() {
      return this.user.gender === this.femaleGender ? 'Шановна' : `${'Шановний' + ' '}${this.personName}`;
    },
    representativeName() {
      return `${this.user.representativeName} ${this.user.representativeMiddleName || ''}`;
    },
    personName() {
      return `${this.user.name} ${this.user.middleName || ''}`;
    },
    adultPersonName() {
      if (this.user.representativeName) return this.representativeName;
      return this.personName;
    },
    cardImageUrl() {
      switch (this.user.cardType) {
        case this.getStudentCardID:
          return require('../../assets/card-progress/Student.jpg');
        case this.getPupilCardID:
          return require('../../assets/card-progress/pupil.jpg');
        case this.getMunicipalCardID:
          return require('../../assets/card-progress/general.jpg');
        default:
          return require('../../assets/card-progress/PrivilegeCardSite.jpg')

      }
    },
    statusImageUrl() {
      switch (this.user.orderStatusId) {
        case orderStatuses.CREATED_BY_USER:
          return require('../../assets/statuses/01.svg');
        case orderStatuses.CREATED_BY_OPERATOR:
          return require('../../assets/statuses/02.svg');
        case orderStatuses.CONFIRMED_BY_USER:
          return require('../../assets/statuses/03.svg');
        case orderStatuses.WAITING_FOR_APPROVAL:
          return require('../../assets/statuses/04.svg');
        case orderStatuses.WAITING_FOR_2ND_APPROVAL:
          return require('../../assets/statuses/45.svg');
        case orderStatuses.WAITING_FOR_PHOTO:
          return require('../../assets/statuses/05.svg');
        case orderStatuses.ORDER_SIGNED:
          return require('../../assets/statuses/06.svg');
        case orderStatuses.WAITING_FOR_EXPORT:
          return require('../../assets/statuses/07.svg');
        case orderStatuses.PHOTO_CONFIRMED:
          return require('../../assets/statuses/75.svg');
        case orderStatuses.CARD_PRINTING:
          return require('../../assets/statuses/08.svg');
        case orderStatuses.CARD_READY:
          return require('../../assets/statuses/09.svg');
        case orderStatuses.CARD_RECEIVED:
          return require('../../assets/statuses/10.svg');
        case orderStatuses.CARD_DESTROYED:
          return require('../../assets/statuses/11.svg');
          case orderStatuses.CARD_RFRESH_PRIVILEGE_INFO:
          return require('../../assets/statuses/13.svg');
        case orderStatuses.CARD_CANCELED:
          return require('../../assets/statuses/100.svg');
        case orderStatuses.CARD_WAITE_BLOCK:
          return require('../../assets/statuses/141.svg');
        case orderStatuses.CARD_BLOCKED:
          return require('../../assets/statuses/142.svg');
        case orderStatuses.CARD_WAITE_UNBLOCK:
          return require('../../assets/statuses/145.svg');
      }
    },
    statusText() {
      return this.statusesList.find(item => item.id === this.user.orderStatusId).text || '';
    },
    paymentText() {
      return this.paymentsList.find(item => item.id === this.user.paymentStatusId).text || '';
    }
  },
  async created() {
    let uid = null;

    if (!this.user) {
      uid = this.getOrderUid;
      // await this.initUser(uid);
    } else {
      uid = this.user.orderUid;
    }

    this.setCurrentStep();

    await this.initAvatar(uid);

    this.generateQR(`https://et.vn.ua/4ec?key=${this.user.orderKey}&pin=${this.user.orderPin}`);

    console.log('Created');
    // init consts for using into template
    this.orderStatuses = orderStatuses;
    this.paymentStatuses = paymentStatuses;
  },
  destroyed() {
    console.log('destroy confirm payment \nshould reset user-data');
    this.$store.commit('setConfirmPaymentUserData', null);
  },
};
