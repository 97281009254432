<template>
  <div class="user-authorization">
    <default-window @close="getUserData">
      <template v-slot:icon>
        <img :src="require(`@/assets/check_status.svg`)" alt="check status" />
      </template>
      <template v-slot:header> ПЕРЕВІРИТИ СТАТУС ЗАМОВЛЕНОЇ КАРТКИ </template>
      <template>
        <form action="" @submit.prevent="getUserData">
          <base-input
            text="Введіть номер замовлення"
            v-model="orderNumber"
            reference="orderNumber"
            :validation="{ required: true, digits: 8 }"
            mask="########"
          >
          </base-input>
          <base-input
            text="Введіть ПІН"
            v-model="pin"
            reference="authPin"
            :validation="{ required: true, digits: 4 }"
            mask="####"
          >
          </base-input>

          <!-- <vue-recaptcha ref="recaptcha" size="invisible" :sitekey="sitekey" @verify="savePersonImage" @expired="onCaptchaExpired"/>-->
        </form>
      </template>
    </default-window>
  </div>
</template>

<script>
import BaseInput from "../BaseInput";
import DefaultWindow from "../DefaultWindow";

export default {
  data() {
    return {
      sitekey: process.env.VUE_APP_RECAPTCHA_KEY,
      pin: "",
      orderNumber: "",
    };
  },
  methods: {
    getUserData() {
      this.$store.dispatch("getUserDataByKey", {
        key: this.orderNumber,
        pin: this.pin,
      });
    },
  },
  components: {
    BaseInput,
    DefaultWindow,
  },
  created() {
    const { key } = this.$route.query;
    const { pin } = this.$route.query;
    if (key && pin) {
      this.orderNumber = key;
      this.pin = pin;
      this.getUserData();
    }
  },
};
</script>

<style scoped lang="scss">
.user-authorization {
  width: calc(100% - 20px);
  display: flex;
  justify-content: center;
  padding: 50px 20px;

  form {
    width: 100%;
    max-width: 400px;
  }

  .btn {
    margin-top: 20px;
    float: right;
    max-width: 150px;
  }
}
</style>
