import cardMixin from '../mixin/commonCardsLogic';
import { mapGetters } from "vuex";

export default {
  mixins: [cardMixin],
  methods: {
    // transformSeries(value) {
    //     this.user.personDocumentSeries = value.toUpperCase();
    // },
    enterEducationalOrganization(value) {
      this.user.educationSchool = value;
    },
  },
  computed: {
    ...mapGetters({
      schools: 'schools'
    })
  },
  created() {

  },
};
