<template>
    <div class="container">
        <div class="row">
            <div class="content">
                <page-header>ПОШИРЕНІ ЗАПИТАННЯ</page-header>

                <p><b>Питання, які виникають у користувачів при роботі із автоматизованою системою обліку оплати проїзду
                        (АСООП), типові. Більшість відповідей ви знайдете на цій сторінці.</b></p>
            </div>
            <div class="content">
                <h5>Стосовно руху громадського транспорту у період військового стану</h5>
                <badger-accordion>
                    <badger-accordion-item>
                        <template slot="header">Як працює громадський транспорт у період військового стану?</template>
                        <template slot="content">
                            <br>
                            <p class="content_text">Транспорт виходить на маршрут у повному обсязі за 
                                <a href="https://depo.vn.ua/news/rozklad-ruhu-gromadskogo-transportu-z-vrahuvannyam#trol">розкладом</a>
                                робочого/вихідного дня з урахуванням дії комендантської години. 
                            <br>
                                Рекомендуємо завантажити застосунок <a href="/apps">Де транспорт Вінниці</a> і відслідковувати реальне 
                                місцезнаходження транспорту на карті.
                            </p>
                        </template>
                    </badger-accordion-item>
                </badger-accordion>
            </div>
            <div class="content">
                <h5>Стосовно банківської картки (БК)</h5>
                <badger-accordion>
                    <badger-accordion-item>
                        <template slot="header">Чому банківська карта <i><b>&laquo;заблокована за
                                    заборгованість&raquo;?</b></i></template>
                        <template slot="content">
                            <br>
                            <p class="content_text">Оплата проїзду БК фіксується валідатором. У випадку, коли зчитування
                                БК пройшло коректно, ви бачите &laquo;зелену галочку&raquo; на валідаторі. Це означає,
                                що системою зафіксовано ваш намір сплатити проїзд, так як гроші із вашого рахунку не
                                списуються миттєво. Ви отримуєте &laquo;авансом&raquo; електронний квиток, який бачать
                                водії та служба контролю.</p>
                            <p class="content_text">Після завантаження з валідатора на сервер інформації про проданий
                                вам квиток, АСООП робить запит про списання вартості проїзду до вашого банку. Якщо банк
                                відмовляє у списанні, картка потрапляє в стоп-лист системи обліку оплати проїзду. При
                                цьому вона і далі діє в торгових та банківських мережах. Повторні запити на списання
                                коштів за отриманий квиток відправляються в автоматичному режимі за графіком через 1
                                год, 1 добу, 4 доби та тиждень. Для успішного списання коштів картка має бути відкрита
                                для розрахунків в Інтернеті.</p>
                            <p class="content_text">Розблокування БК у системі обліку оплати проїзду відбудеться
                                автоматично, одразу після дозволу банку на списання коштів з вашого рахунку для
                                погашення боргу за раніше отриманий квиток. </p>
                            <p class="content_text">Для прискорення погашення заборгованості або повтору запиту, якщо їх
                                вже вичерпано, скористайтесь функціоналом &laquo;погасити заборгованість по
                                картці&raquo; у мобільному застосунку<a href="/apps"> Е-квиток Вінниці</a>.</p>
                                <hr class="line">
                        </template>
                    </badger-accordion-item>

                    <badger-accordion-item>
                        <template slot="header">Як <b>погасити заборгованість</b> за банківською
                            карткою?</template>
                        <template slot="content">
                            <div class="text_bloc">
                                <br>
                                <p class="content_text">
                                    <b>Якщо ви оплачували проїзд смартфоном через Google Pay/Apple Pay</b>
                                  </p> 
                                    

                                 <p class="content_text">
                                   Для розблокування картки і погашення заборгованості додайте віртуальну картку Google Pay або Apple Pay до мобільного застосунку <a href="/apps">"Е-квиток
                                Вінниці"</a>, як банківську картку:
                                <ul>
                                    <li>зареєструйтесь у мобільному застосунку <a href="/apps">"Е-квиток Вінниці"</a>
                                        (зверніть увагу, для реєстрації необхідно активувати свою пошту за посиланням у
                                        листі);</li>
                                    <li>додайте <b>віртуальний</b> номер картки;</li>

                                    <li> натисніть на кнопку <b><i>"Погасити заборгованість"</i></b>.</li>
                                </ul>
                                </p>
                                <p class="content_text">
                             Віртуальний номер банківської картки можна дізнатись так:
                             <ul>
<li>
на терміналі iBox оберіть банк, до якого не належить ваша картка: "Моно" або "ПУМБ". Зверніть увагу, обрано має бути саме сторонній банк, не ваш! Далі інтерфейс пропонує ввести номер вручну або прикласти картку до рідера.</li>
 <li> Приклададіть смартфон до рідера терміналу iBox;</li>
 <li>
отримайте віртуальний номер на екрані, поспішіть його сфотографувати.</li>
 </ul>
  Після списання боргу ваша картка видалиться зі стоп листа системи. Наступного ранку валідатор завантажить стоп лист без обмежень по вашій картці.
     </p>
                
                                                           
                                <hr class="subline">
                               
                                <p class="content_text">
                                    <b>Якщо ви оплачували проїзд пластиковою карткою:</b>
                                <ul>
                                    <li>зареєструйтесь у мобільному застосунку <a href="/apps">"Е-квиток Вінниці"</a>
                                        (зверніть увагу, для реєстрації необхідно активувати свою пошту за посиланням у
                                        листі);</li>
                                    <li>додайте банківську картку; </li>
                                    <li>натисніть на кнопку <b><i>"Погасити заборгованість"</i></b>.</li>
                                </ul>
                                Після списання боргу ваша картка видалиться зі стоп листа системи. Наступного ранку
                                валідатор звантажить стоп лист без обмежень по вашій картці.
                                </p><hr class="line">
                                
                            </div>
                        </template>
                    </badger-accordion-item>


                    <badger-accordion-item>
                        <template slot="header">Чому я бачу повідомлення <i><b>&laquo;невідповідна картка&raquo;</b></i>
                            на екрані валідатора при оплаті пластиковою банківською карткою?</template>
                        <template slot="content">
                            <br>
                            <p class="content_text"> Можливі причини:
                                <ul>
                                    <li>
                                        <b>картка не прочитана валідатором </b>через наявність в &laquo;полі&raquo;
                                        зчитувача більше однієї картки. Якщо ви прикладаєте картку разом з гаманцем, то
                                        переконайтесь у відсутності в ньому інших карток;
                                    </li>
                                    <li>
                                        <b>антена або чіп NFC в картці механічно пошкоджені.</b> Такі пошкодження
                                        унеможливлюють безконтактну оплату і в інших місцях. Картку потрібно замінити;
                                    </li>
                                    <li>
                                        <b>для вашої картки банком емітентом не передбачено здійснення таких оплат.</b> У
                                        цьому випадку використовуйте для оплати інший метод.
                                    </li>
                                </ul>
                                </p><hr class="line">
                            
                        </template>
                    </badger-accordion-item>

                    <badger-accordion-item>
                        <template slot="header">Чому я бачу повідомлення <i><b>&laquo;невідповідна картка&raquo;</b></i>
                            на екрані валідатора при оплаті смартфоном?</template>
                        <template slot="content">
                            <br>
                            <p class="content_text">
                                Такі повідомлення можуть бути у декількох випадках, але розглянемо лише ті, де оплата у
                                торгових точках здійснюється коректно, а на валідаторі оплату здійснити неможливо.
                            </p>
                            <p class="content_text">
                                У більшості випадків така проблема виникає зі смартфонами &laquo;Сяомі&raquo;,
                                &laquo;Нокіа&raquo; та тими, які нещодавно обновились до 10-ї версії
                                &laquo;Android&raquo;. Для інших моделей також рекомендуємо перевірити це налаштування.
                            </p>
                            <p class="content_text">
                                У інженерному меню здійсніть налаштування параметра:
                                <br>
                                <i><b>
                                        settings > more > secure element position and select HCE Wallet.
                                    </b></i>
                            </p>
                            <p class="content_text">
                                Після зміни цього параметра та перезавантаження пристрою оплата стане можливою.
                            </p>
                            <hr class="line">
                        </template>
                    </badger-accordion-item>


                    <badger-accordion-item>
                        <template slot="header">Чому списання з рахунку проходять із запізненням?</template>
                        <template slot="content">
                            <br>
                            <p class="content_text">
                                В системі оплата банківськими картами здійснюється офлайн, як в літаках. Задача
                                валідатора опрацювати оплату за 1-2 секунди і за умови обробки без доступу до мережі
                                Інтернет.
                            </p>
                            <p class="content_text">
                                Запит приходить до вашого банку тоді, коли інформація про вашу оплату завантажена до
                                системи з транспортного борту, що додатково дозволяє запобігати помилковим повторним
                                списанням.
                            </p>
                            <p class="content_text">
                                Вивантаження з валідатора в ідеальній ситуації відбуваються кожні 5 хвилин по 3G, іноді
                                - лише по приїзду в депо через WiFi. Також може статись, що через несправність або,
                                наприклад, ДТП валідатор вивантажує інформацію із запізненням до декількох днів.
                            </p>
                            <p class="content_text">
                                Ви можете бути впевнені, що ми прикладаєм максимум зусиль з технічного нагляду за
                                елементами системи, на роботу яких ми можемо вплинути.
                            </p>
                            <hr class="line">
                        </template>
                    </badger-accordion-item>


                    <badger-accordion-item>
                        <template slot="header">Чи знімається <b>зайва оплата</b> помилково? Як отримати
                            звіт?</template>
                        <template slot="content">
                            <p class="content_text">
                                <br>
                                Якщо у вас виникла така підозра, то потрібно перевірити список ваших поїздок. Для цього:
                            </p>
                            <p class="img-row">
                                <span class="img-row__img-wrap"> <img
                                        src="../assets/e_ticket_38_38.png" /></span><span>зареєструйтесь у застосунку <a
                                        href="/apps">"Е-квиток Вінниці"</a> (зверніть увагу, для реєстрації потрібно
                                    активувати свою пошту за посиланням у листі);</span>
                            </p>

                            <p class="img-row">
                                <span class="img-row__img-wrap"><img src="../assets/add_card.png" />
                                </span><span>додайте пластикову банківську картку або віртуальний номер банківської картки, якщо ви оплачували проїзд смартфоном;</span>
                            </p>

                            <p class="img-row">
                                <span class="img-row__img-wrap"><img :src="require(`@/assets/list_raport.svg`)"> </span>
                                <span class="img-row__img-wrap"><img :src="require(`@/assets/send_raport.svg`)"> </span>
                                <span>отримайте та проаналізуйте звіт, що надійде на пошту.</span>
                            </p>

                            <p class="content_text">
                                У кожному рядку звіту дві дати: <i><b>"Дата/час поїзки"</b></i> та <i><b>"Дата/час
                                        списання"</b></i>. Знайдіть рядки у яких <i><b>"Дата/час поїздки"</b></i>
                                однакові.
                            </p>
                            <p class="content_text">
                                Раді повідомити, що за час роботи системи такі дублювання не виявлені.
                            </p>
                            <hr class="line">
                        </template>
                    </badger-accordion-item>

                    <badger-accordion-item>
                        <template slot="header">При оплаті через <b>Google Pay</b> або <b>Apple Pay</b> потрібно
                            знати!</template>
                        <template slot="content">
                            <p class="content_text">
                                <br>
                                Технологія оплати через смартфон передбачає використання <b>віртуального номера</b> БК,
                                який відрізняється від фактичного, що на пластику. Усі системи взаємодіють саме з
                                віртуальним номером, який на рівні банку зв'язаний з вашим банківським рахунком. Через
                                це оплати смартфоном і пластиком в АСООП відображаються з різних номерів, а списання
                                здійснюється з вашого основного рахунку.
                            </p>
                            <p class="content_text">
                                Це стосується не лише звіту. Якщо інструментом оплати був смартфон, то до терміналу
                                контролера пасажир має підносити свій інструмент оплати - смартфон, а не БК.
                            </p>
                            <div class="img_ticket_shadow">
                                <img src="../assets/ticket_white.png">
                            </div>
                            <p class="content_text">
                                Зверніть увагу, що на паперовому чеку, який друкується валідатором, вказується частина
                                саме віртуального номера БК.
                            </p>

                            <p class="content_text">
                                Віртуальний номер картки Google Pay або Apple Pay можна додати до мобільного застосунку <a href="/apps">"Е-квиток
                                Вінниці"</a>, для цього потрібно ввести всі цифри вашого віртуального номера.
                            </p>
                            <p class="content_text">
                             Пропонуємо метод, <b>як дізнатись свій віртуальний номер картки</b>, хоча це скоріше "лайфхак", ніж офіційний метод:
                             <ul>
<li>
на терміналі iBox оберіть банк, до якого не належить ваша картка: "Моно" або "ПУМБ". Зверніть увагу, обрано має бути саме сторонній банк, не ваш! Далі інтерфейс пропонує ввести номер вручну або прикласти картку до рідера.</li>
 <li> Приклададіть смартфон до рідера терміналу iBox;</li>
 <li>
отримайте віртуальний номер на екрані, поспішіть його сфотографувати.</li>
 </ul>
   </p>
<p class="content_text">
Маючи віртуальний номер, ви легко зможете отримувати звіти по поїздкам та списанням коштів у застосунку <a href="/apps">Е-Квиток Вінниці</a> в режимі онлайн, додавши цей номер, як банківську картку.
                            </p>
                            <p class="content_text">
                                Звіт по таким оплатам можна також отримати при наявності <b><i>паперового чека з
                                        валідатора</i></b>:
                            <ul>
                                <li>
                                    у офісі за адресою Соборна, 36;
                                </li>
                                <li>
                                    за запитом у <a href="https://t.me/VinnytsiaMKV"> чат техпідтримки</a>.
                                </li>
                            </ul>
                            </p>

                            <hr class="line">
                        </template>
                    </badger-accordion-item>
                    
                    <badger-accordion-item>
                        <template slot="header">Чи є квиток, роздрукований валідатором, доказом оплати проїзду
                            банківською карткою?</template>
                        <template slot="content">
                            <br>
                            <p class="content_text">
                                Квиток, який друкується валідатором на других дверях борту, не є доказом оплати проїзду.
                                <b>Доказом є тільки інструмент оплати: банківська картка або смартфон!</b>
                            </p>
                            <p class="content_text">
                                Друк обумовлений лише вимогами законодавства щодо наявності паперового квитка (чека) при
                                розрахунку банківськими картками (відповідно до п.4. ст.9 ЗУ "Про застосування
                                реєстраторів розрахункових операцій у сфері торгівлі, громадського харчування та
                                послуг", від 06.07.1995 № 256/95-ВР).
                            </p>
                            <hr class="line">
                        </template>
                    </badger-accordion-item>

                     

                </badger-accordion>
            </div>



            <div class="content">
                <h5>Стосовно користування Муніципальною карткою вінничанина (МКВ)</h5>
                <badger-accordion>
                    <badger-accordion-item>
                        <template slot="header">Як я можу перевірити баланс МКВ?</template>
                        <template slot="content">
                            <p class="content_text">
                                <br>
                                Натисніть кнопку &laquo;Інфо&raquo; на валідаторі, піднесіть МКВ до зчитувача
                                валідатора, ознайомтесь з інформацію про стан всіх продуктів на картці.
                            </p>
                            <hr class="line">
                        </template>
                    </badger-accordion-item>


                    <badger-accordion-item>
                        <template slot="header">Чому на сайті інформація про стан рахунку МКВ відрізняється від тієї,
                            яку я бачу, натиснувши кнопку &laquo;Інфо&raquo; на валідаторі?
                        </template>
                        <template slot="content">
                            <p class="content_text">
                                <br>
                                Актуальна інформація про стан продуктів зберігається безпосередньо в чіпі картки і
                                оновлюється миттєво при контакті із валідатором або касовим терміналом у <a
                                    href="/buy-offline">пункті поповнення</a>.
                                Інформація про транзакції по картці із валідаторів/терміналів завантажується на сервер
                                із затримкою.
                                Тому, найактуальніша інформація про стан продуктів МКВ у будь-який момент часу міститься
                                на самій картці і відображається на валідаторі, на борту транспорта у розділі
                                &laquo;Інфо&raquo; або в касовому терміналі у пункті поповнення.
                            </p>
                            <hr class="line">
                        </template>
                    </badger-accordion-item>



                    <badger-accordion-item>
                        <template slot="header">Якщо Муніципальну Картку Вінничанина було поповнено через <a
                                href="/card_service_b">сайт et.vn.ua</a> або у додатку платіжної системи чи банку, або у
                            терміналі самообслуговування, чому баланс картки не змінився?</template>
                        <template slot="content">
                            <p class="content_text">
                                <br>
                                У <a href="/buy-offline">пунктах поповнень та продажу карток </a> на касовому терміналі
                                поповнення МКВ здійснюється миттєво.
                            </p>
                            <p class="content_text">
                                <b>Процес поповнення МКВ засобами онлайн-сервісів складається із двох основних
                                    обов'язкових етапів:</b>
                            <ol>
                                <li>
                                    Oнлайн-поповнення.
                                </li>
                                <li>
                                    Активація онлайн-поповнення (запис коштів) безпосередньо на вашу картку. Для цього
                                    картка повинна фізично мати контакт з валідатором.
                                </li>
                            </ol>
                            </p>
                            <p>
                                <b> Детальний алгоритм поповнення МКВ засобами онлайн-сервісів:</b>
                            <ol>
                                <li>
                                    При поповненні МКВ платіжна система перевіряє, чи може вона отримати з вашого
                                    рахунку від вашого банку необхідну суму. Якщо може, то з вашого рахунку знімаються
                                    кошти та перераховується в АСООП із зазначенням номера картки і типу продукту.
                                </li>
                                <li>
                                    АСООП формує транзакцію для поповнення. У цей момент грошей на карті ще немає.
                                </li>
                                <li>
                                    Валідатори на бортах транспорту отримують інформацію про дану транзакцію поповнення
                                    не раніше наступної доби, а саме не раніше перезміни бортів, яка відбувається об 11 ранку.
                                    Якщо картка поповнювалась до 9 години вечора, то активація можлива і вранці. 
                                    Якщо ж пізніше, а особливо з 23, то оновлення даних на бортах не відбудеться раніше обіду.
                                </li>
                                <li>
                                    Активація онлайн поповнення (запис коштів) безпосередньо на картку здійснюється на борту транспорту, на валідаторі. На
                                    валідаторі натисніть кнопку &laquo;Інфо&raquo; та
                                    прикласти картку до зчитувача. На сайті інформація про стан продуктів буде оновлена
                                    з часом.
                                </li>
                                <li>
                                    Валідатор, на якому була здійснена активація онлайн поповнення передає в систему
                                    інформацію про подію поповнення. Через 5-10 хвилин АСООП отримує її, змінює запис
                                    про баланс на сайті і в мобільному застосунку та робить картку доступною для наступного
                                    поповнення.
                                </li>
                            </ol>
                            </p>
                            <hr class="line">
                        </template>
                    </badger-accordion-item>


                </badger-accordion>
                    <badger-accordion>
                        <badger-accordion-item>
                            <template slot="header">Що означає повідомлення <b><i>"Закінчився термін дії"</i></b> при
                                реєстрації проїзду?</template>
                            <template slot="content">

                                <!-- <div>
        <img :src="require(`@/assets/card_is_not_valid.svg`)" alt="card is not valid"> 
                    <img src="../assets/card_is_not_valid.jpg" alt="card is not valid">
                        </div> -->

                                <p class="content_text">
                                    <br>
                                    “Закінчився термін дії” - помилка списання коштів з неактивованного гаманця (який
                                    жодного разу не поповнювався).
                                </p>
                                <p class="content_text">
                                    Для <b>пільгових</b> карток повідомлення “Закінчився термін дії” означає, що
                                    пільгові поїздки поточного місяця вичерпані або термін дії пільги закінчився.
                                    Наступна оплата має здійснюватись із гаманця, який необхідно активувати,
                                    поповнивнивши його.
                                </p>
                                <hr class="line">
                            </template>
                        </badger-accordion-item>

                        <badger-accordion-item>
                            <template slot="header">Що робити коли валідатор повідомляє <i><b>&laquo;Карта несправна.
                                        Зверніться в пункт видачі карт&raquo;</b></i>?</template>
                            <template slot="content">
                                <p class="content_text">
                                    <br>
                                    Спробуйте здійснити оплату на іншому валідаторі, їх зазвичай декілька на борту
                                    транспортного засобу, якщо і там побачите таке ж повідомлення, терміново завітайте з
                                    карткою до нашого офісу на Соборній, 36.
                                    А поки що оплатіть проїзд іншим, доступним вам методом.
                                </p>
                                <p class="content_text">
                                    Технічні працівники відновлять вашу картку з усіма залишками, які були у вас на
                                    картці до моменту руйнування структури даних.
                                </p>
                                <p class="content_text">
                                    Руйнування структури даних доволі рідкісне явище, пов’язане з особливістю технології
                                    NFC, і відбувається лише тоді, коли в момент запису карту було виведено з
                                    &laquo;активного поля&raquo; зчитувача валідатора. Щоразу тримайте картку, аж поки
                                    не побачите &laquo;зелену галочку&raquo; на валідаторі.
                                </p>
                                <hr class="line">
                            </template>
                        </badger-accordion-item>
                        <badger-accordion-item>
                            <template slot="header">Що робити коли термінал у пункті продажу та поповнення пише
                                <i><b>&laquo;Дані зруйновано&raquo;</b></i>?</template>
                            <template slot="content">

                                <p class="content_text">
                                    <br>
                                    В цьому випадку потрібно терміново звернутись до працівника центру обслуговування на
                                    Соборній, 36. Технічні працівники відновлять дані на вашій картці з усіма залишками,
                                    які були до моменту руйнування структури даних.
                                </p>
                                <p class="content_text">
                                    Руйнування структури інформації доволі рідкісне явище, пов’язане з особливістю
                                    технології NFC, і відбувається тоді, коли в момент запису карту було виведено з
                                    &laquo;активного поля&raquo; зчитувача валідатора. Щоразу тримайте картку біля
                                    зчитувача, до появи &laquo;зеленої галочки&raquo; на екрані валідатора.
                                </p>

                            </template>
                        </badger-accordion-item>
                    </badger-accordion>

            </div>




            <div class="content">
                <h5>Стосовно замовлення/отримання Муніципальної картки вінничанина (МКВ)</h5>

                <badger-accordion>
                    <badger-accordion-item>
                        <template slot="header">Де я можу замовити/отримати/придбати МКВ?</template>
                        <template slot="content">
                            <br>
                            <p class="content_text">
                                Це залежить від <a href="/cards-description">типу МКВ </a>, який ви хочете отримати:
                            </p>
                            <ul class="content_text">
                                <li><a href="/travel"> Неперсоніфіковані </a> МКВ продаються в мережі <a
                                        href="/buy-offline">пунктів поповнень та продажу карток</a>. Ви можете придбати
                                    таку картку і одразу поповнити її.
                                </li>
                                <li>
                                    <a href="/municipal">Загальні</a>, <a href="/student">студентські </a>та <a
                                        href="/pupillary">учнівські </a> МКВ замовляються <a href="/order-card">на сайті
                                    </a> або у <a href="/service-center">центрах обслуговування</a>. Замовлення та
                                    оплата здійснюються онлайн. Після замовлення на вашу поштову скриньку надсилається
                                    лист із посиланням на кабінет, у якому відображається актуальний стан виконання
                                    замовлення та за вимогою модератора інструмент для завантаження фото. Строк
                                    виготовлення картки займає не менше місяця.
                                </li>
                                <li>
                                    <a href="/privilege">Пільгові </a>картки надаються жителям Вінницької ТГ вперше
                                    безкоштовно. Для замовлення потрібно особисто завітати до <a
                                        href="/service-center">центру обслуговування</a> з необхідними <a
                                        href="/privilege">документами</a>. Коли картка буде виготовлена, вам буде
                                    надіслане повідомлення на мобільний телефон.
                                </li>
                            </ul>
                            <hr class="line">
                        </template>
                    </badger-accordion-item>


                    <badger-accordion-item>
                        <template slot="header">Чи можна замовити пільгову МКВ через сайт?</template>
                        <template slot="content">
                            <br>
                            <p class="content_text">
                                Замовлення <a href="/privilege">пільгових карток </a>здійснюється за особистим звернення
                                до <a href="/service-center">центру обслуговування </a>.
                            </p>
                            <hr class="line">
                        </template>
                    </badger-accordion-item>




                    <badger-accordion-item>
                        <template slot="header">Чи можу я замовити Загальну/Студентську/Учнівську МКВ у центрі
                            обслуговування або на сайті?
                        </template>
                        <template slot="content">
                            <br>
                            <p class="content_text">
                                Так, замовлення можна здійснити як на <a href="/order-card">сайті</a>, так і у <a
                                    href="/service-center">центрі обслуговування</a>. У центрі обслуговування зверніться
                                до працівника та покажіть необхідні<a href="/cards-description">документи</a>.
                            </p>
                            <hr class="line">
                        </template>
                    </badger-accordion-item>




                    <badger-accordion-item>
                        <template slot="header">Чи можна відмінити виготовлення картки, якщо вона була замовлена
                            помилково?</template>
                        <template slot="content">
                            <br>
                            <p class="content_text">
                                Якщо замовлення не оплачено, можете не робити жодних дій. Замовлення буде анульовано
                                автоматично.
                            </p>
                            <p class="content_text">
                                Якщо замовлення оплачено і завантажено на виробництво, відміна виготовлення картки
                                неможлива та грошові кошти не повертаються.
                            </p>
                            <p class="content_text">
                                Якщо замовлення оплачено, але не завантажене на виробництво, то є можливість замінити
                                замовника на іншу особу. Для цього завітайте в центр обслуговування за адресою Собoрна,
                                36.
                            </p>
                            <hr class="line">
                        </template>
                    </badger-accordion-item>


                    <badger-accordion-item>
                        <template slot="header">Чому мені приходить лист про невідповідність фотографії вимогам та що
                            потрібно робити?</template>
                        <template slot="content">
                            <br>
                            <p class="content_text">
                                Фотографія, яку ви завантажили, при оформленні замовлення (або повторно), не відповідає
                                вимогам до фото на документ.
                            </p>
                            <p class="content_text">
                                У листі міститься посилання до кабінету із інструментом для повторного завантаження
                                фотографії і описом вимог.
                            </p>
                            <p class="content_text">
                                Фото має відповідати хоча б мінімальним вимогам: оптимальна якість зображення,
                                вертикальне розміщення обличчя, відсутність зайвих об’єктів у кадрі, без застосування
                                фільтрів (навіть ефекту чорно-білого зображення).
                            </p>
                            <hr class="line">
                        </template>
                    </badger-accordion-item>


                </badger-accordion>
            </div>



            <div class="content">
                <h5>Стосовно мобільного застосунку "Е-квиток Вінниці"</h5>

                <badger-accordion>
                    <badger-accordion-item>
                        <template slot="header">Як отримати інформацію про списання коштів за проїзд з банківської
                            картки?</template>
                        <template slot="content">
                            <p class="content_text">
                                <br>
                                У <a href="/apps">мобільному застосунку</a> оберіть банківську картку. Натисніть кнопку
                                "Надіслати звіт" для отримання на електронну пошту інформації про поїздки і відповідні
                                списання. Перевірте пошту. Звертаємо вашу увагу на те, що додавання у додаток
                                віртуальної картки Google Pay або Apple Pay неможливе.
                            </p>
                            <p class="content_text">
                                Для отримання звіту <b>у режимі онлайн</b> доступною є <a
                                    href="https://card-holder.et.vn.ua/"> web-версія </a> мобільного додату.
                                У даній версії введіть логін, пароль, виберіть банківську картку та отримайте звіт.
                            </p>
                            <p class="content_text">
                                У звіті міститься інформація про дати придбання квитків та дати списання коштів із
                                рахунку за відповідні поїздки.
                                Це є зручним способом отримання інформації по вашій банківській картці, якщо ви не
                                бажаєте йти до центру обслуговування.
                            </p>
                            <p class="content_text">
                                Звіт по банківській картці щодо списань коштів за проїзд можна також замовити у центрі
                                обслуговування за адресою Соборна, 36. За письмовою заявою вам буде надано звіт у
                                друкованому вигляді.
                            </p>
                            <hr class="line">
                        </template>
                    </badger-accordion-item>
                    <badger-accordion-item>
                        <template slot="header">Як дізнатись <b>пін-код</b> картки для додавання її у
                            додаток?</template>
                        <template slot="content">
                            <br>
                            <p class="content_text">
                                Пін-коди до всіх типів муніципальних карток можна дізнатись у <a
                                    href="/buy-offline">пунктах поповнення та продажу карток</a>. Зверніться до
                                працівника пункту поповнення із карткою.
                            </p>
                            <hr class="line">
                        </template>
                    </badger-accordion-item>
                    <badger-accordion-item>
                        <template slot="header">Чи можу я додати віртуальну картку, яка була створена у Google Pay або
                            Apple Pay?
                        </template>
                        <template slot="content">
                            <br>
                            <p class="content_text">
                                Віртуальний номер картки Google Pay або Apple Pay можна додати до мобільного застосунку <a href="/apps">"Е-квиток
                                Вінниці"</a>, для цього потрібно ввести всі цифри вашого віртуального номера.
                            </p>
                            <p class="content_text">
                             Пропонуємо метод, <b>як дізнатись свій віртуальний номер картки</b>, хоча це скоріше "лайфхак", ніж офіційний метод:
                             <ul>
<li>
на терміналі iBox оберіть банк, до якого не належить ваша картка: "Моно" або "ПУМБ". Зверніть увагу, обрано має бути саме сторонній банк, не ваш! Далі інтерфейс пропонує ввести номер вручну або прикласти картку до рідера.</li>
 <li> Приклададіть смартфон до рідера терміналу iBox;</li>
 <li>
отримайте віртуальний номер на екрані, поспішіть його сфотографувати.</li>
 </ul>
   </p>
<p class="content_text">
Маючи віртуальний номер, ви легко зможете отримувати звіти по поїздкам та списанням коштів у застосунку <a href="/apps">Е-Квиток Вінниці</a> в режимі онлайн, додавши цей номер, як банківську картку.
                            </p>
                     
                            <hr class="line">
                        </template>
                    </badger-accordion-item>
                </badger-accordion>
            </div>



            <div class="content">
                <h5>Стосовно онлайн поповнення</h5>

                <badger-accordion>
                    <badger-accordion-item>
                        <template slot="header">Чому на картці відсутня сума успішного онлайн поповнення?</template>
                        <template slot="content">
                            <br>
                            <p class="content_text">
                                На картці відсутня сума успішного онлайн поповнення, тому що кошти не активовані на
                                картку. Активуйте кошти онлайн поповнення на картку на валідаторі у транспорті.
                            </p>
                            <div>
                                <img src="../assets/activation_online_replenishment.gif">
                            </div>
                            <p class="content_text">Процес <b><a href="/card-howto#recharge_activation">поповнення картки </a>засобами
                                    онлайн-сервісів </b>складається із двох обов'язкових етапів: </p>
                            <ol class="content_text">
                                <li><b>Oнлайн-поповнення.</b>
                                </li>
                                <li>Подальшої <b>активації онлайн-поповнення (запис коштів) </b>безпосередньо на вашу
                                    картку.
                                </li>
                            </ol>

                            <p class="content_text"><b><a href="/card-howto#recharge_activation">Активація онлайн поповнення</a></b>
                                здійснюється на борту транспорту. Валідатори на бортах транспорту отримують інформацію про дану транзакцію поповнення
                                    не раніше наступної доби, а саме не раніше перезміни бортів, яка відбувається об 11 ранку.
                                    Якщо картка поповнювалась до 9 години вечора, то активація можлива і вранці. 
                                    Якщо ж пізніше, а особливо з 23, то оновлення даних на бортах не відбудеться раніше обіду.
<a
                                    href="/card-howto#recharge_activation_val">Для активації на валідаторі </a> натисніть кнопку
                                <b><i>Інфо</i></b>, прикладіть картку та утримуйте її до завершення активації. У вікні
                                &laquo;Інфо&raquo;, що з'явиться автоматично після завершення поповнення, відобразиться
                                актуальна сума.
                            </p>
                            <hr class="line">
                        </template>
                    </badger-accordion-item>

                    <badger-accordion-item>
                        <template slot="header">Чому онлайн поповнення гаманця недоступне?</template>
                        <template slot="content">
                            <br>
                            <div>
                                <img src="../assets/activation_online_replenishment.gif">
                            </div>
                            <p class="content_text">
                                Онлайн поповнення гаманця недоступне, тому що <b>попереднє поповнення </b>не активоване
                                на картку.
                                Активуйте незараховані кошти попереднього онлайн поповнення на картку на валідаторі у
                                транспорті.
                            </p>
                            <p class="content_text">Процес <b><a href="/card-howto#05">поповнення картки </a>засобами
                                    онлайн-сервісів </b>складається із двох обов'язкових етапів: </p>
                            <ol class="content_text">
                                <li><b>Oнлайн-поповнення.</b>
                                </li>
                                <li>Подальшої <b>активації онлайн-поповнення (запис коштів) </b>безпосередньо на вашу
                                    картку.
                                </li>
                            </ol>

                            <p class="content_text"><b><a href="/card-howto#recharge_activation">Активація онлайн поповнення</a></b>
                                здійснюється на борту транспорту. Валідатори на бортах транспорту отримують інформацію про дану транзакцію поповнення
                                    не раніше наступної доби, а саме не раніше перезміни бортів, яка відбувається об 11 ранку.
                                    Якщо картка поповнювалась до 9 години вечора, то активація можлива і вранці. 
                                    Якщо ж пізніше, а особливо з 23, то оновлення даних на бортах не відбудеться раніше обіду.
<a
                                    href="/card-howto#recharge_activation_val">Для активації на валідаторі </a> натисніть кнопку
                                <b><i>Інфо</i></b>, прикладіть картку та утримуйте її до завершення активації. У вікні
                                &laquo;Інфо&raquo;, що з'явиться автоматично після завершення поповнення, відобразиться
                                актуальна сума.
                            </p>
                            <p class="content_text">
                                Активуйте попереднє онлайн поповнення. І наступне поповнення стане доступним.
                            </p>
                            <hr class="line">
                        </template>
                    </badger-accordion-item>


                    <badger-accordion-item>
                        <template slot="header">Чому онлайн поповнення електронного проїзного недоступне?</template>
                        <template slot="content">
                            <br>
                            <p class="content_text">
                                Розглянемо можливі причини і шляхи вирішення:
                            <ol class="content_text">
                                <li>
                                    Онлайн поповнення проїзного недоступне, тому що <b>попереднє поповнення </b>не
                                    активоване на картку.
                                    <br><a href="/card-howto#recharge_activation">Активація онлайн поповнення</a> на борту транспорту. Валідатори на бортах транспорту отримують інформацію про дану транзакцію поповнення
                                    не раніше наступної доби, а саме не раніше перезміни бортів, яка відбувається об 11 ранку.
                                    Якщо картка поповнювалась до 9 години вечора, то активація можлива і вранці. 
                                    Якщо ж пізніше, а особливо з 23, то оновлення даних на бортах не відбудеться раніше обіду.

                                    <a href="/card-howto#recharge_activation_val">Для активації на валідаторі </a> натисніть кнопку
                                    <b><i>Інфо</i></b>, прикладіть картку та утримуйте її до завершення активації. У
                                    вікні &laquo;Інфо&raquo;, що з'явиться автоматично після завершення поповнення,
                                    відобразиться актуальна сума.
                                </li>
                                <li>
                                    Поточний проїзний є діючим.
                                    <br> Наступне онлайн поповнення проїзного стане доступним за день до завершення
                                    терміну дії поточного проїзного.
                                </li>
                            </ol>
                            </p>
                            <p class="content_text">

                            </p>
                            <hr class="line">
                        </template>
                    </badger-accordion-item>

                    <badger-accordion-item>
                        <template slot="header">Чому баланс на сайті відображається не такий, як на валідаторі у вікні
                            &laquo;Інфо&raquo;. І де правильно?</template>
                        <template slot="content">
                            <p class="content_text">
                                <br>
                                Актуальна інформація про стан продуктів зберігається безпосередньо в чіпі картки і
                                оновлюється миттєво при контакті із валідатором або касовим терміналом у <a
                                    href="/buy-offline">пункті поповнення</a>.
                            </p>
                            <p class="content_text">
                                Інформація про транзакції по картці із валідаторів/терміналів завантажується на сервер
                                із затримкою.
                            </p>
                            <p class="content_text">
                                Тому, найактуальніша інформація про стан продуктів МКВ у будь-який момент часу міститься
                                на самій картці і відображається на валідаторі, на борту транспорта у розділі <a
                                    href="/card-howto/#06">&laquo;Інфо&raquo; </a>або в касовому терміналі у пункті
                                поповнення.
                            </p>
                            <hr class="line">
                        </template>
                    </badger-accordion-item>
                </badger-accordion>
            </div>




            <div class="content">
                <h5>Стосовно здійснення контролю оплати проїзду</h5>

                <badger-accordion>
                    <badger-accordion-item>
                        <template slot="header">Як здійснюється контроль оплати проїзду?</template>
                        <template slot="content">
                            <p class="content_text">
                                <br>
                                Оплата і контроль проїзду здійснюється повністю офлайн.
                            </p>
                            <p class="content_text">
                                Для оплати/реєстрації проїзду <b>пасажир</b> прикладає свій інструмент оплати
                                (МКВ/банківську картку(БК)/смартфон) до валідатора, за необхідністю оплачує проїзд
                                пасажирів/багажу.
                            </p>
                            <p class="content_text">
                                Для здійснення контролю <b>контролери</b>:
                            <ul>
                                <li>натискають кнопку &laquo;Контроль&raquo; на терміналі водія для переведення
                                    валідаторів у режим &laquo;Інспекція&raquo;;</li>
                                <li>копіюють із терміналу водія інформацію про оплату/реєстрацію проїзду пасажирів з
                                    моменту виходу борту на маршрут на свій &laquo;Термінал контролера&raquo;;</li>
                                <li>підходять до пасажира для перевірки. Пасажир підносить до терміналу контролера свій
                                    інструмент оплати/реєстрації (МКВ/БК/смартфон). На терміналі контролер бачить
                                    інформацію, що квиток придбаний на борту і відповідає
                                    інструменту оплати пасажира або була зареєстрована пересадка.</li>
                            </ul>
                            </p>
                            <p class="content_text">
                                Відсутність оплати/реєстрації проїзду (МКВ/БК/смартфон) або реєстрації пересадки (тільки
                                МКВ) прирівнюється до неоплати.
                            </p>
                             <p class="content_text">
                                Оплата/реєстрація проїзду персоніфікованою МКВ вважається дійсною, якщо вона була здійснена
власником картки. При здійсненні оплати/реєстрації проїзду особою, яка не є 
власником персоніфікованої картки: оплата проїзду вважається не здійсненою; картка вилучається контролерами; картка блокується;
у разі поверененні картки власнику, картка розблоковується.
                            </p>
                            <hr class="line">
                        </template>
                    </badger-accordion-item>
                    <badger-accordion-item>
                        <template slot="header">Чи є квиток, роздрукований валідатором, доказом оплати проїзду
                            банківською карткою?</template>
                        <template slot="content">
                            <br>
                            <p class="content_text">
                                Квиток, який друкується валідатором на других дверях борту, не є доказом оплати проїзду.
                                <b>Доказом є тільки інструмент оплати: банківська картка або смартфон!</b>
                            </p>
                            <p class="content_text">
                                Друк обумовлений лише вимогами законодавства щодо наявності паперового квитка (чека) при
                                розрахунку банківськими картками (відповідно до п.4. ст.9 ЗУ "Про застосування
                                реєстраторів розрахункових операцій у сфері торгівлі, громадського харчування та
                                послуг", від 06.07.1995 № 256/95-ВР).
                            </p>
                            <hr class="line">
                        </template>
                    </badger-accordion-item>

                </badger-accordion>
            </div>
        
        </div>
    </div>
</template>

<script>
import PageHeader from "../components/commonComponents/PageHeader";
import { BadgerAccordion, BadgerAccordionItem } from "vue-badger-accordion";

export default {
  components: {
    PageHeader,
    BadgerAccordion,
    BadgerAccordionItem,
  },
};
</script>

<style scoped lang="scss">
h2 {
  margin-bottom: 20px;
}

p {
  span {
    font-weight: bold;
    margin-top: 20px;
  }
}

.img-row {
  display: flex;
  align-items: center;

  .img-row__img-wrap {
    width: 100%;
    max-width: 38px;
    margin: 0px, 0px, 0px, 0px;
    margin-right: 10px;
  }
}

img {
  float: left;
  margin: 0px, 0px, 0px, 0px;
  padding-right: 10px;
}

service {
  display: block;
  max-width: 100%;
  height: auto;
}

h5 {
  color: #b54285;
  margin-bottom: 20px;
}

a {
  color: #b54285;
}

.badger-accordion__panel {
  max-height: 75vh;
  overflow: hidden;
}

.badger-accordion__panel.-ba-is-hidden {
  max-height: 0 !important;
}

.badger-accordion--initalised .badger-accordion__panel {
  transition: max-height ease-in-out 0.2s;
}

.content_text {
  color: #555555;
}

.img_ticket_shadow {
  float: right;
  margin: 0px, 0px, 0px, 0px;
  padding-right: 10px;
  box-shadow: 4px 4px 8px 1px rgba(35, 31, 32, 0.35);
}

.line {
  border: none;
  height: 2px;
  color: #e7e7e7;
  background-color: #434343;
}

.subline {
  border: none;
  height: 2px;
  color: #e7e7e7;
  background-color: #e7e7e7;
}

.text_bloc {
  background-color: #f7f7f7;
}
</style>