import axios from 'axios';
import urlMapping from '../../urlMapping';
import router from '../../../router';

export default {
  state: {
    user: null,
    orderAccessToken: '',
    paymentUrl: null,
    successUrl: process.env.NODE_ENV === 'development' ? 'http://abvgd.s62.in.ua' + '/successful-payment/' : `${window.location.protocol}//${window.location.host}/successful-payment/`,
    failedUrl: process.env.NODE_ENV === 'development' ? 'http://abvgd.s62.in.ua' + '/failed-payment/' : `${window.location.protocol}//${window.location.host}/failed-payment/`,
  },
  getters: {
    getConfirmPaymentUserData(state) {
      return state.user;
    },
    getOrderAccessToken(state) {
      return state.orderAccessToken
    },
  },
  mutations: {
    setConfirmPaymentUserData(state, user) {
      state.user = user;
    },
    setPaymentUrl(state, url) {
      state.paymentUrl = url;
    },
    setOrderAccessToken(state, token) {
      state.orderAccessToken = token
    }
  },
  actions: {
    getUserEmailByKey(context, payload) {
      const url = urlMapping.getUserDataByKeyAndPinUrl(payload);

      return axios.get(url)
        .then((response) => {
          const email = response.data.order.personEmail;
          const token = response.data.token;
          context.commit('setOrderAccessToken', token);
          return email;
        })
        .catch((err) => {
          console.warn(err);
          context.commit('toggleErrorWindow', true);
          return '';
        });
    },
    getUserDataByKey(context, payload) {
      context.commit('toggleLoadingIndicator');
      const url = urlMapping.getUserDataByKeyAndPinUrl(payload);

      axios.get(url)
        .then((response) => {
          const user = response.data.order;
          const token = response.data.token;
          context.commit('setOrderAccessToken', token);
          context.commit('setConfirmPaymentUserData', user);
          router.push('/card-progress');
        })
        .catch((err) => {
          console.warn(err);
          /* смс про неправильний пароль */

          if (err.response.data < 0) {
            context.commit('setBlockMinutes', err.response.data);
            context.commit('toggleErrorLoginWindow', true);
          }
          else if (err.response.data > 0) {
            context.commit('setBlockMinutes');
            context.commit('toggleErrorLoginWindow', true);
          }
          else {
            context.commit('toggleErrorWindow', true);
          }
        })
        .finally(() => {
          context.commit('toggleLoadingIndicator');
        });
    },
    getUserDataByUid(context, uid) {
      const url = urlMapping.getOrderByUidUrl(uid);
      return axios.get(url, {
        headers: {
          'Authorization': 'Bearer ' + context.getters.getOrderAccessToken
        }
      })
        .then((response) => {
          const user = response.data;
          context.commit('setConfirmPaymentUserData', user);
          router.push('/card-progress');
        })
        .catch((err) => {
          console.warn(err);
          context.commit('toggleErrorWindow', true);
        });
    },
    async getUserPhotoForFailedPayment(context, uid) {
      const url = urlMapping.getUserPhotoForFailedPaymentUrl(uid);

      return axios.get(url, {
        responseType: 'blob',
        headers: {
          'Authorization': 'Bearer ' + context.getters.getOrderAccessToken
        }
      })
        .then((response) => {
          return response.data;
        })
        .then(data => data)
        .catch((err) => {
          console.warn('Failed to download image');
          console.warn(err);
          context.commit('toggleErrorWindow', true);
          return null;
        });
    },
    getPaymentUrl(context, { uid, key, pin }) {
      const url = urlMapping.getPaymentUrl();

      const successUrl = process.env.NODE_ENV === 'development'
        ? 'http://abvgd.s62.in.ua' + '/successful-payment' + `?key=${key}${pin}`
        : `${window.location.protocol}//${window.location.host}/successful-payment` + `?key=${key}${pin}`;
      const failedUrl = process.env.NODE_ENV === 'development'
        ? 'http://abvgd.s62.in.ua' + '/failed-payment' + `?key=${key}${pin}`
        : `${window.location.protocol}//${window.location.host}/failed-payment` + `?key=${key}${pin}`;

      return axios.post(url, {
        orderUid: uid,
        successUrl,
        failedUrl,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.getOrderAccessToken
        }
      })
        .then((response) => {
          console.log(response);
          return response.data.paymentUrl;
        })
        .catch((err) => {
          console.warn(err);
          context.commit('toggleLoadingIndicator');
          context.commit('toggleErrorWindow', true);
        });
      // .finally(() => {
      //
      // })
    },
    verifyCardByUser(context, uid) {
      const url = urlMapping.getVerifyCardByUserUrl(uid);
      return axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + context.getters.getOrderAccessToken
        }
      })
        .then((response) => {
          console.log(response);
          // current status will be 3

          context.state.user.orderStatusId = response.data.currentStatus;
          context.state.user.orderStatusDate = response.data.statusDate;
          // second step

          return response.data.currentStatus;
        })
        .then((status) => {
          // update all info
          if (status !== 3) {
            console.log('UPDATE USER ALL INFO');
            context.dispatch('getUserDataByUid', context.state.user.orderUid);
            return false;
          }

          return true;

        })
        .catch((err) => {
          console.log(err);
          return false;
        });
    },
    changeUserPhoto(context, { uid, personPhoto, recaptchaToken }) {
      context.commit('toggleLoadingIndicator');

      const url = urlMapping.getChangingPhotoUrl(uid);

      const formData = new FormData();
      formData.append('OrderUid', uid);
      formData.append('PersonPhoto', personPhoto);
      formData.append('RecaptchaToken', recaptchaToken);

      return axios({
        method: 'post',
        url,
        data: formData,

        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + context.getters.getOrderAccessToken
        }

      })
        .then(response => response.status === 200)
        .catch(err => {
          console.warn('Failed to change image');
          console.warn(err);
          context.commit('toggleErrorWindow', true);
          return null;
        })
        .finally((data) => {
          context.commit('toggleLoadingIndicator');
        })
    }
  },
};
