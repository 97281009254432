<template>
  <div class="normative-documents">
    <div class="container">
      <div class="row">
        <div class="content">
          <page-header>Нормативні документи</page-header>
          <ol class="normative-documents__list">
            <li>
              Закон України №1812-VIII від 17.01.2017 «Про внесення змін до
              деяких законодавчих актів України щодо впровадження
              автоматизованої системи обліку оплати проїзду в міському
              пасажирському транспорті»
              <a href="https://zakon.rada.gov.ua/laws/show/1812-19"> читати </a>
            </li>
            <li>
              Постанова Кабінету Міністрів України №812 від 25 жовтня 2017 р.
              «Про внесення змін до деяких постанов Кабінету Міністрів України»,
              <a href="https://zakon.rada.gov.ua/laws/show/812-2017-%D0%BF#Text"> читати </a>
            </li>
            <!--
            <li>
              Наказ Директора КП «Вінницякартсервіс» №23 від 08 липня 2019,
              <a href="documents/order.pdf"> читати </a>
            </li>
-->
            <li>
              Політика конфіденційності мобільного застосунку CardHolder 
              <a href="documents/cardholder_agreement.pdf" target="_blank"
                > читати</a>
            </li>
            <!--
            <li>
              Рішення Виконавчого комітету ВМР "Про затвердження Порядку надання
              пільг на проїзд у міському електричному та автомобільному
              транспорті загального користування, який працює в звичайному
              режимі руху, окремим категоріям громадян Вінницької міської
              територіальної громади" від 30.12.2020 р. № 2918
              <a href="documents/№2918_30_12_2020.pdf" target="_blank"
                >читати</a
              >
            </li>
            <li>
              Рішення Виконавчого комітету ВМР "Про реорганізацію комунального підприємства «Вінницякартсервіс»" 12.01.2023 р. No 45
              <a href="documents/№45_12_01_2023.pdf" target="_blank"
                >читати</a
              >
            </li>
          -->

          <li>
            Рішення ВМР "Про затвердження Порядку функціонування
та вимог до автоматизованої системи обліку
оплати проїзду в міському електричному та
автомобільному транспорті загального
користування у Вінницькій міській територіальній
громаді та системи автоматичного визначення
місцезнаходження транспорту незалежно від форм
власності, а також видів, форм носіїв, порядку обігу
та реєстрації проїзних документів" від 26.05.2023 р. № 1665
              <a href="documents/№1665_26_05_2023.pdf" target="_blank"
                > читати</a
              >
            </li>
          <li>
              Рішення ВМР "Про затвердження Правил користування
міським автомобільним та електричним
транспортом загального користування
у місті Вінниці та населених пунктах,
що входять до Вінницької міської
територіальної громади" від 07.07.2023 р. № 1735
              <a href="documents/№1735_07_07_2023.pdf" target="_blank"
                > читати</a
              >
            </li>

            




          </ol>
        </div>

        <div class="content">
          <h2>Бланки документів для юридичних осіб</h2>

          <ol class="normative-documents__list_doc">
            <li>
              Договір про надання агентських послуг (Договір приєднання),
              редакція від 04 листопада 2019 року,
              <ul class="normative-documents__list1">
                <li>
                  Договір
                  <a href="documents/agent_agreement.pdf" target="_blank">
                    читати
                  </a>
                </li>
                <li>
                  Угода про внесення змін до договору (НОВІ ПЛАТІЖНІ РЕКВІЗИТИ)
                  <a
                    href="documents/Change_details_Agreement.pdf"
                    target="_blank"
                  >
                    читати
                  </a>
                </li>
                <li>
                  Угода про внесення змін до договору про надання агентських послуг (договір приєднання)
                  <a
                    href="documents/Change_details_Agreement_accession.pdf"
                    target="_blank"
                  >
                    читати
                  </a>
                </li>
                <li>
                  Угода про внесення змін до договору про надання агентських послуг (договір приєднання)
                  <a
                    href="documents/Assignee_Municipal_Enterprise_VIС_07_07_23.pdf"
                    target="_blank"
                  >
                    читати
                  </a>
                </li>
                </ul>
            </li>


                     <li>
              Договір продажу smart карток
              <ul class="normative-documents__list1">
                <li class="download-icons">
                  <a
                    href="documents/Contract_purchase_sale_commercial.docx"
                    download
                  >
                    Договір з купівлі-продажу карток для комерційних підприємств
                  </a>
                  <span class="download-icons__img-wrap">
                    <a
                      href="documents/Contract_purchase_sale_commercial.docx"
                      download
                    >
                      <img :src="require(`@/assets/download.svg`)" />
                    </a>
                  </span>
                </li>
                <li class="download-icons">
                  <a
                    href="documents/Contract_purchase_sale_budget.docx"
                    download
                  >
                    Договір з купівлі-продажу карток для бюджетних підприємств
                  </a>
                  <span class="download-icons__img-wrap">
                    <a
                      href="documents/Contract_purchase_sale_budget.docx"
                      download
                    >
                      <img :src="require(`@/assets/download.svg`)" />
                    </a>
                  </span>
                </li>
                <li class="download-icons">
                  <a href="documents/Annex_purchase_sale.docx" download>
                    Додаток до договору
                  </a>
                  <span class="download-icons__img-wrap">
                    <a href="documents/Annex_purchase_sale.docx" download>
                      <img :src="require(`@/assets/download.svg`)" />
                    </a>
                  </span>
                </li>
              </ul>
            </li>

            <li>
              Договір про надання послуг зі справляння плати за транспортні
              послуги
              <ul class="normative-documents__list1">
                <li class="download-icons">
                  <a
                    href="documents/Contract_replenishment_commercial.docx"
                    download
                  >
                    Договір на поповнення карток для комерційних підприємств
                  </a>
                  <span class="download-icons__img-wrap">
                    <a
                      href="documents/Contract_replenishment_commercial.docx"
                      download
                    >
                      <img :src="require(`@/assets/download.svg`)" />
                    </a>
                  </span>
                </li>
                <li class="download-icons">
                  <a
                    href="documents/Contract_replenishment_commercial_long.docx"
                    download
                  >
                    Договір на поповнення карток для комерційних підприємств  з пролонгацією
                  </a>
                  <span class="download-icons__img-wrap">
                    <a
                      href="documents/Contract_replenishment_commercial_long.docx"
                      download
                    >
                      <img :src="require(`@/assets/download.svg`)" />
                    </a>
                  </span>
                </li>
                <li class="download-icons">
                  <a
                    href="documents/Contract_replenishment_budget.docx"
                    download
                  >
                    Договір на поповнення карток для бюджетних підприємств
                  </a>
                  <span class="download-icons__img-wrap">
                    <a
                      href="documents/Contract_replenishment_budget.docx"
                      download
                    >
                      <img :src="require(`@/assets/download.svg`)" />
                    </a>
                  </span>
                </li>
                <li class="download-icons">
                  <a
                    href="documents/Annex_purchase_replenishment.docx"
                    download
                  >
                    Додаток до договору
                  </a>
                  <span class="download-icons__img-wrap">
                    <a
                      href="documents/Annex_purchase_replenishment.docx"
                      download
                    >
                      <img :src="require(`@/assets/download.svg`)" />
                    </a>
                  </span>
                </li>
              </ul>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "../commonComponents/PageHeader";

export default {
  components: {
    PageHeader,
  },
};
</script>

<style scoped lang="scss">
.normative-documents {
  width: 100%;
}

.container {
  /*background-color: #fff;*/
}

.normative-documents__header {
  width: 100%;
  text-align: left;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #c33d83;
  font-family: Century Gothic, Arial, sans-serif;
  text-transform: uppercase;
  padding-left: 20px;
}

.normative-documents__list {
  width: 100%;
  padding-bottom: 2rem;

  a {
    color: #c33d83;
  }
}

.normative-documents__list_doc {
  width: 100%;
  padding-bottom: 4rem;
  font-size: 1rem;
  font-weight: bold;
}

.normative-documents__list1 {
  width: 100%;
  padding-bottom: 1rem;
  font-weight: normal;
  list-style-type: none;

  a {
    color: #c33d83;
  }
}

h2 {
  font-size: 24px;
  padding-bottom: 2rem;
}

.download-icons {
  display: flex;
  align-items: center;

  .download-icons__img-wrap {
    width: 100%;
    max-width: 24px;
    margin-left: 10px;
  }

  img {
    background-color: transparent;
    box-shadow: none;
    margin: 0;
  }

  a {
    color: #434343;
  }

  a:hover {
    color: #c33d83;
    text-decoration: none;
  }
}
</style>
