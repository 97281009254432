<template>
    <h1 class="page-header">
        <slot></slot>
    </h1>
</template>

<script>
export default {};
</script>

<style scoped>
    .page-header {
        margin-bottom: 30px;
        color: #C33D83;
    }
</style>
