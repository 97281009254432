<template>
  <div class="normative-documents">
    <div class="container">
        <div class="row">
            <div class="content">
            <page-header>Політика конфіденційності мобільного застосунку CardHolder</page-header>

<p>Ця Політика конфіденційності (далі – Політика) мобільного застосунку CardHolder (далі – Застосунок CardHolder) застосовується до Інформації, яку комунальне підприємство “Вінницький інформаційний центр” (далі – Підприємство) може отримати з пристрою Користувача під час користування застосунком CardHolder (Е-квиток Вінниці).</p>
<p>Застосунок CardHolder та послуги в рамках Застосунку CardHolder реалізуються Користувачу на підставі документів, які, окрім іншого, регулюють всі питання обробки і зберігання Підприємством персональних даних Користувача. </p>
<p>Застосунок CardHolder не збирає ніякої інформації Користувача.</p>
<p>Застосунок CardHolder не пропонує ніяких вбудованих покупок.</p>
<p>При розрахунку банківською карткою через захищені канали зв'язку КП "Вінницький інформаційний центр" отримує виключно SHA2 номера карти для підтвердження поїздок в транспорті по даній карті.</p>
<p>Анонімна інформація, яка надходить від сторонніх сервісів, як-от Google Analytics, Google Play та інших, містить наступне: інформацію про встановлення застосунку CardHolder (наприклад, тип операційної системи і номер версії програми), інформацію про пристрій (апаратна модель і версія операційної системи) та інформацію про поведінку Користувача.</p>
<p>Всі дані, які ми збираємо, використовуються лише для внутрішнього аналізу і досліджень з метою поліпшення сервісу.</p>
<p>Ми не несемо відповідальність за наслідки використання Застосунку CardHolder, виникнення збоїв в пристроях або їх несправність.</p>
<p>Користування Застосунком CardHolder означає беззастережну згоду Користувача з цією Політикою щодо вказаних умов обробки Інформації, яка може бути одержана з пристрою Користувача.</p>
<p>Користувач підтверджує ознайомлення та згоду із застереженнями щодо персональних даних.</p>
<p>Політика конфіденційності може періодично змінюватися. Ми будемо розміщувати інформацію про будь-які зміни Політики конфіденційності на цій сторінці. Користувач зобов’язується самостійно ознайомлюватися з актуальною редакцією Політики.</p>
        <p>Якщо у Вас є будь-які питання щодо нашої Політики конфіденційності, будь ласка, пишіть нам на e-mail адресу:  et.vn.ua@gmail.com.</p>
      <p>Для видалення вашого аккаунту і всієї персональної інформації надішліть листа на пошту et.vn.ua@gmail.com з повідомленням "Видаліть мій аккаунт у застосунку Е-квиток Вінниці" з пошти, на яку було зареєстровано користувача.</p>
    <p>
      Застосунок збирає лише адресу електронної пошти, яка потрібна для реєстрації та авторизації у ньому.
</p>
<p>
Застосунок крім електронної пошти не збирає жодної особистої інформації про користувачів, включаючи їхні імена, номери телефонів або будь-які інші дані, які можуть бути використані для їхньої ідентифікації.
</p>
<p>
Застосунок не зберігає жодної інформації про використання користувачами, включаючи історію переглядів, дані про геолокацію або будь-які інші дані, які можуть бути використані для відстеження їхніх дій.
<p>
Застосунок використовує сторонні платіжні рішення для поповнення транспортних карток. 
</p>
<p>
Користувачі можуть бути впевнені, що використання цього застосунку є безпечним та анонімним.
</p>

    </p>  
    </div>

        </div>
    </div>
  </div>
</template>

<script>
import PageHeader from './commonComponents/PageHeader';

export default {
  components: {
    PageHeader,
  },
};
</script>

<style scoped lang="scss">
.normative-documents {
  width: 100%;
}

.normative-documents__header {
  width: 100%;
  text-align: left;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #c33d83;
  font-family: Century Gothic, Arial, sans-serif;
  text-transform: uppercase;
  padding-left: 20px;
}

.normative-documents__list {
  width: 100%;
  padding-bottom: 2rem;
  font-size: 1.1rem;
  a {
    color: #c33d83;
  }
}
</style>
