<template>
  <div class="container">
    <div class="row">
      <div class="content">
        <router-link href to="/municipal">
          <div class="prv_b"></div>
        </router-link>
        <router-link href to="/pupillary">
          <div class="nxt_b"></div>
        </router-link>
        <h1>Пільгова муніципальна картка вінничанина</h1>
        <div class="row row-card">
          <div class="col-md-6 col-card">
            <div class="row">
              <div class="col-md-5 midd">
                <img src="../../../../assets/Card_New.png" />
                <div class="person">
                  <span class="icon-personalization"></span>Персоніфікована
                </div>
              </div>
              <div class="col-md-7">
                <ul>
                  <li>
                    <span class="icon-lowered"></span>Безкоштовний пакет поїздок
                  </li>
                  <li>
                    <span class="icon-transfer"></span>Пересадка протягом 60 хв
                  </li>
                  <li>
                    <span class="icon-balance-recovery"></span>Відновлення
                    балансу при втраті картки
                  </li>
                  <li></li>
                  <li>
                    <span class="yellow icon-calendar"></span>Термін дії
                    проїзного пакету - місяць
                  </li>
                  <!--          <span class="icon-calendar"></span>Термін дії поточного поповнення - 60 календарних днів
                  </li>
          -->
                  <li>
                    <span class="icon-purse"></span>Гаманець для поповнення
                    рахунку
                  </li>
                  <li>
                    <span class="icon-making"></span>Перший випуск 0 грн.,
                    повторний - 60 грн.
                  </li>
                  <li>
                    <span class="icon-payment"></span>Оплата - безготівкова
                  </li>
                  <li>
                    <span class="icon-usage-term"></span>Термін використання - 5
                    років
                  </li>
                </ul>
              </div>
            </div>
            <hr />
            <div class="icon-group">
              <span class="icon-tram"></span>
              <span class="icon-trolley-bus"></span>
              <span class="icon-bus"></span>
            </div>
            <table class="table table-striped">
              <tbody>
                <tr>
                  <td>
                    <a href="/beneficiaries#p_1"
                      ><span> п. 1-20, 24,25, 27-30, 32 </span></a
                    >
                  </td>
                  <td>Пакет "60 поїздок"</td>
                  <td>0 грн.</td>
                </tr>
                <tr>
                  <td>
                    <a href="/beneficiaries#p_22"><span>п. 21-23,26 </span></a>
                  </td>
                  <td>Пакет "120 поїздок (із супроводом)"</td>
                  <td>0 грн.</td>
                </tr>
                <tr>
                  <td>
                    <a href="/beneficiaries#p_33"><span>п. 31,33 </span></a>
                  </td>
                  <td>Пакет "Безлімітний"</td>
                  <td>0 грн.</td>
                </tr>
              </tbody>
            </table>
            <hr />
            <div class="row row-purse">
              <div class="col-md-4">
                <span class="icon-purse"></span>
                <div class="purse">Гаманець</div>
              </div>
              <div class="col-md-7">
                <div class="price">
                  <div class="price">
                    <span class="icon-tram"></span>
                    <div>8 грн.</div>
                  </div>
                  <div class="price">
                    <span class="icon-trolley-bus"></span>
                    <div>8 грн.</div>
                  </div>
                  <div class="price">
                    <span class="icon-bus"></span>
                    <div>12 грн.</div>
                  </div>
                </div>
                <div class="minibus">
                  <span class="icon-minibus"></span>
                  <div>
                    15/18 грн., без пересадки
                    <span>приватні перевізники</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-card">
            <div class="about-card">
              <p>
                <span>Пільгова муніципальна картка вінничанина</span>
                - це персоніфікована картка, призначена для реєстрації проїзду
                <a href="/beneficiaries">пільговими категоріями громадян,</a>
                які мають право на безоплатний проїзд у громадському транспорті.
              </p>
              <p>Перша видача пільгової муніципальної картки безкоштовна!</p>
              <p>
                Пільгова муніципальна картка надалі дозволить користуватись
                низкою інших муніципальних послуг.
              </p>
              <p>
                Пільговику, який не досягнув 18-річного віку, картку оформляє та
                отримує його представник (мати, батько або опікун).
              </p>
              <hr />
              <p>
                У випадку, коли пільговик, який досягнув 18-річного віку, не в
                змозі особисто надати необхідні документи, отримати електронний
                квиток та/або особисто бути присутнім, його інтереси може
                представляти представник. Повноваження представника
                підтверджується нотаріально посвідченою довіреністю.
                <!--  <a href="/online-queue">
                                    Детальніше...
                </a>-->
              </p>
              <p>
                Довіреність військовослужбовця або іншої особи, яка перебуває на
                лікуванні у госпіталі, санаторії та іншому
                військово-лікувальному закладі, може бути посвідчена начальником
                цього закладу, його заступником з медичної частини, старшим або
                черговим лікарем.
              </p>
              <p>
                Довіреність військовослужбовця, а в пунктах дислокації
                військової частини, з'єднання, установи, військово-навчального
                закладу, де немає нотаріуса чи органу, що вчиняє нотаріальні
                дії, а також довіреність робітника, службовця, члена їхніх сімей
                і члена сім'ї військовослужбовця може бути посвідчена командиром
                (начальником) цих частини, з'єднання, установи або закладу.
              </p>
              <p>
                Довіреність особи, яка перебуває у місці позбавлення волі
                (слідчому ізоляторі), може бути посвідчена начальником місця
                позбавлення волі.
              </p>
              <p>
                Довіреності, посвідчені зазначеними посадовими особами,
                прирівнюються до нотаріально посвідчених.
              </p>
              <hr />
              <p>
                <span>Як отримати пільгову МКВ</span>
              </p>
              <ol class="order-card-flow">
                <li>
                  <div class="li-inner">
                    <span>Замовити картку.</span>
                    Замовлення здійснюється у
                    <a href="/service-center">центрі обслуговування.</a>
                    
                     <p class="docs-sublist__header">
                      У центрі обслуговування необхідно відповідно до черги
                      звернутись до консультанта та показати оригінали:
                    </p>
                    <ul class="docs-sublist">
                      <li>
                        - документа, що посвідчує особу пільговика (свідоцтва
                        про народження, паспорту або ID картки);
                      </li>
                      <li>
                        - ідентифікаційного номеру пільговика, якщо є, або
                        довідки про відмову;
                      </li>
                      <li>- документа, що підтверджує право на пільгу;</li>
                      <li>
                        - довідки МСЕК - за наявності інвалідності I-II групи.
                      </li>
                      <li>
                        - медичного висновку - для дітей з інвалідністю від 6 років, які не досягли 18-ти річного віку.
                      </li>
                    </ul>
                    <p class="docs-sublist__header">
                      Якщо картку замовляє
                      <span
                        >представник пільговика, який не досягнув 18-ти річного
                        віку,</span
                      >
                      крім вищеперелічених додатково показати оригінали:
                    </p>
                    <ul class="docs-sublist">
                      <li>
                        - документа, що посвідчує особу представника (паспорту
                        або ID картки);
                      </li>
                      <li>
                        - ідентифікаційного номеру представника, або довідки про
                        відмову;
                      </li>
                      <li>
                        - фото пільговика (надається представником, якщо
                        пільговик не в змозі особисто бути присутнім).
                      </li>
                    </ul>
                    <p class="docs-sublist__header">
                      Якщо картку замовляє
                      <span
                        >представник пільговика, який досягнув 18-ти річного
                        віку</span
                      >
                      та не в змозі замовити картку самостійно, показати
                      оригінали:
                    </p>
                    <ul class="docs-sublist">
                      <li>
                        - нотаріально посвідченої довіреності представника (якщо
                        пільговик, досягнув 18-ти річного віку);
                      </li>
                      <li>
                        - документа, що посвідчує особу представника (паспорту
                        або ID картки);
                      </li>
                      <li>
                        - документа, що посвідчує особу пільговика (свідоцтва
                        про народження, паспорту або ID картки);
                      </li>
                      <li>
                        - ідентифікаційного номера пільговика, якщо є, або
                        довідки про відмову;
                      </li>
                      <li>- документа, що підтверджує право на пільгу;</li>
                      <li>
                        - за наявності інвалідності I-II групи - довідки МСЕК.
                      </li>
                      <li>- фото пільговика.</li>
                    </ul>

                    <p class="mt-3">
                      Консультант повинен зробити фото заявника та оформити
                      заяву на отримання картки.
                    </p>
                  </div>
                </li>
                <li>
                  <div class="li-inner">
                    <span>Отримати підтвердження про виготовлення картки</span>
                    у SMS або листі на електронній пошті.
                  </div>
                </li>
                <li>
                  <div class="li-inner">
                    <span>Отримати пільгову МКВ із пакетом поїздок.</span>

                    Прийти у обраний день та час у відповідний центр
                    обслуговування та звернутись до консультанта.
                    <p class="docs-sublist__header">
                      Якщо картку отримує
                      <span
                        >пільговик, який на момент оформлення заяви досягнув
                        18-річного віку,</span
                      >
                      показати:
                    </p>
                    <ul class="docs-sublist">
                      <li>- документ, що посвідчує особу пільговика;</li>
                      <li>- ідентифікаційний номер пільговика.</li>
                    </ul>
                    <p class="docs-sublist__header">
                      Якщо картку отримує
                      <span
                        >представник пільговика, який на момент оформлення заяви
                        не досягнув 18-ти річного віку</span
                      >
                      показати:
                    </p>

                    <ul class="docs-sublist">
                      <li>- документ, що посвідчує особу пільговика;</li>
                      <li>- ідентифікаційний номер пільговика.</li>
                      <li>
                        - документ, що посвідчує особу представника
                        учня/студента;
                      </li>
                      <li>
                        - ідентифікаційний номер представника учня/студента.
                      </li>
                    </ul>

                    <p class="docs-sublist__header">
                      Якщо картку отримує
                      <span
                        >представник пільговика, який досягнув 18-ти річного
                        віку</span
                      >
                      та не в змозі отримати картку самостійно, показати:
                    </p>

                    <ul class="docs-sublist">
                      <li>
                        - нотаріально посвідчену довіреність представника.
                      </li>
                      <li>- документ, що посвідчує особу пільговика;</li>
                      <li>- ідентифікаційний номер пільговика.</li>
                      <li>
                        - документ, що посвідчує особу представника пільговика.
                      </li>
                    </ul>
                  </div>
                </li>
              </ol>
            </div>
            <div class="order-btn">
              <!--
              <base-button
                type="button"
                class="btn"
                @click="orderCard('0')"
                disabled
                >Замовити онлайн</base-button
              >
              -->
              <base-button
                type="button"
                class="btn"
                @click="navigateUrl('/service-center', '0')"
                >Центри обслуговування</base-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import orderCardMixin from "../orderCardsMixin";
import BaseButton from "../../../BaseButton";

export default {
  mixins: [orderCardMixin],
  components: {
    BaseButton,
  },
};
</script>

<style scoped lang="scss" src="./styles.scss"></style>
<style scoped lang="scss" src="../commonStyles.scss"></style>
