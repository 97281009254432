<template>
  <div>
    <iframe
      src="http://176.102.49.157:30080/registration.html"
      frameborder="0"
      width="100%"
      height="500px"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: 'index',
};
</script>

<style scoped lang="scss" src="./style.scss"></style>
