<template>
  <div class="container bg-white">
    <div class="row">
      <div class="col-12">
        <h2 class="d-flex justify-content-center">
          Особистий кабінет перевізника
        </h2>
        <div class="info" v-if="carrierData">
          <h4 class="d-flex">Загальна інформація:</h4>
          <span class="d-flex">
            {{ carrierData.carrierTitle }}
          </span>
          <span class="d-flex"
            >договір №{{ carrierData.carrierAgreementNumber }}, що діє до
            {{ getDateFormat(carrierData.carrierEndDate) }}
          </span>
          <span class="d-flex"
            >Конт. телефон {{ carrierData.carrierPhone }}</span
          >
          <span class="d-flex"
            >Електронна скринька {{ carrierData.carrierEmail }}</span
          >
          <span class="d-flex">Рахунок IBAN {{ carrierData.carrierIban }}</span>
          <p class="text-warning">
            Якщо ви знайшли невідповідність у вказаній інформації або бажаєте
            змінити пароль просимо терміново повідомити про це
            КП ”Вінницький інформаційний центр” (м. Вінниця. вул. Соборна, 36) для внесення
            змін.
          </p>
        </div>
        <div>
          <badger-accordion ref="myAccordion">
            <badger-accordion-item>
              <template slot="header"><h4>Список бортів:</h4> </template>
              <template slot="content"
                ><div v-for="vehicle in carrierVehicles" :key="vehicle.id">
                  <span>
                    <b>{{ vehicle.text }}</b>
                  </span>
                  <span class="d-flex ml-2"
                    >Закріплення терминалів за цим бортом:</span
                  >
                  <ol v-if="terminalsForVehicle(vehicle).length">
                    <li
                      class="mb-3"
                      v-for="terminal in terminalsForVehicle(vehicle)"
                      :key="terminal.terminalId"
                    >
                      <span class="d-flex">
                        ID: {{ terminal.terminalRrn }} SER:
                        {{ terminal.terminalSerial }}
                      </span>
                      <span class="span-date"
                        >Встановлено {{ getDateFormat(terminal.ctStartDate) }} -
                        {{
                          terminal.ctEndDate
                            ? "Знято " + getDateFormat(terminal.ctEndDate)
                            : "досі встановлений"
                        }}</span
                      >
                    </li>
                  </ol>
                  <span v-else class="text-warning span-date">
                    Цей борт не має жодного встановленого терміналу!
                  </span>
                </div>
              </template>
            </badger-accordion-item>
            <badger-accordion-item>
              <template slot="header"
                ><h4>Інформація про нарахування за оплачені поїздки:</h4>
              </template>
              <template slot="content"
                ><span class="text-warning"
                  >Зверніть увагу, що нарахування здійснюється в дату
                  завантаження успішної транзакції у базу даних АСООП.
                  Зараховується здійснюється перевізнику, за яким був
                  закріплений термінал на момент оплати.</span
                >
                <br />
                <br />
                <div
                  v-for="billing in items(carrierBillings, isAllBillings)"
                  :key="billing.carrierBillingDate"
                >
                  <span
                    >Дата {{ getDateFormat(billing.carrierBillingDate) }} Сума
                    {{ toMoneyFormat(billing.carrierBillingSumma) }}</span
                  >
                </div>
                <span
                  v-if="defaultCount < carrierBillings.length"
                  class="is-all-show"
                  @click="isAllBillings = !isAllBillings"
                  >{{ isAllBillings ? "Згорнути" : "Показати усі" }}</span
                >
              </template>
            </badger-accordion-item>
            <badger-accordion-item>
              <template slot="header"
                ><h4>Автоматично створені платежі:</h4>
              </template>
              <template slot="content"
                ><span class="text-warning">
                  В системі вказані лише заплановані в автоматичному режимі
                  платіжні документи, реальна транзакція може відрізнятись датою
                  проведення, або взагалі бути скасованою банківським
                  процесінгом. Дані вказані лише орієнтовно, просимо самостійно
                  звіряти з випискою по вашому рахунку.</span
                >
                <br />
                <br />
                <div
                  v-for="payment in items(carrierPayments, isAllPayments)"
                  :key="payment.carrierPaymentDate"
                >
                  <span
                    >Дата {{ getDateFormat(payment.carrierPaymentDate) }} Сума
                    {{ toMoneyFormat(payment.carrierPaymentSumma) }}</span
                  >
                </div>
                <span
                  v-if="defaultCount < carrierPayments.length"
                  class="is-all-show"
                  @click="isAllPayments = !isAllPayments"
                  >{{ isAllPayments ? "Згорнути" : "Показати усі" }}</span
                >
              </template>
            </badger-accordion-item>
          </badger-accordion>
        </div>
        <span class="d-flex"
          ><b>Орієнтовний баланс </b>&nbsp; на {{ getDateFormat(Date.now()) }}:
          {{ toMoneyFormat(carrierBalance) }} грн
        </span>
        <span class="text-warning">
          Може відрізнятись від фактичного, актуальний баланс підтверджується
          проведенням звірки взаєморозрахунків.
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { BadgerAccordion, BadgerAccordionItem } from "vue-badger-accordion";
export default {
  name: "CarrierCabinet",
  components: {
    BadgerAccordion,
    BadgerAccordionItem,
  },
  data() {
    return {
      isAllPayments: false,
      isAllBillings: false,
      defaultCount: 10,
      carrierData: null,
      carrierVehicles: [],
      carrierTerminals: [],
      carrierPayments: [],
      carrierBillings: [],
      carrierBalance: 0,
      currentTab: "info",
    };
  },

  async created() {
    let resp = await this.$store.dispatch("getCarrierInfo");
    if (resp?.response?.status == 401 || resp?.data == null) {
      this.$router.push("/popa-login");
    } else {
      this.carrierData = resp.data.carrierInfo;
      this.carrierVehicles = resp.data.carrierVehicle;
      this.carrierTerminals = resp.data.carrierTerminals;
      this.carrierPayments = resp.data.carrierPayments;
      this.carrierBillings = resp.data.carrierBillings;
      this.carrierBalance = resp.data.carrierInfo.carrierBalance;
    }
  },
  methods: {
    toMoneyFormat(num) {
      try {
        let tempNum = num.toFixed(2);
        let remainder = tempNum.slice(tempNum.indexOf("."));
        return num.toLocaleString("ru-RU") + remainder;
      } catch (err) {
        return "-0";
      }
    },
    getDateFormat(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    terminalsForVehicle(item) {
      return this.carrierTerminals.filter(
        (el) => el.ctCarrierVehicleId == item.id
      );
    },
    items(list, isAllShow) {
      if (!list.length) {
        return [];
      }
      setTimeout(() => {
        this.$refs["myAccordion"].calculateAllPanelsHeight();
      }, 100);
      return isAllShow ? list : list.slice(0, this.defaultCount);
    },
  },
};
</script>
<style lang="scss">
.tab {
  overflow: hidden;
  button {
    background-color: rgb(255, 255, 255);
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
  }
  button.active {
    background-color: rgb(66, 110, 255);
  }
  button:hover {
    background-color: rgb(118, 129, 88);
  }
}
/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}
.span-date {
  padding-left: 10px;
  font-size: 14px;
}
.is-all-show {
  text-decoration: underline;
  cursor: pointer;
}
</style>