import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
  },
  methods: {
    transformSeries(prop, value) {
      this.user[prop] = value.toUpperCase();
    },
  },
};
