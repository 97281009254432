<template>
  <div id="app">
    <the-header></the-header>
    <main id="main">
      <router-view></router-view>
      <loading-indicator></loading-indicator>
      <error-window></error-window>
      <login-error-window></login-error-window>
      <login-carrier-error-window></login-carrier-error-window>
    </main>
    <the-footer></the-footer>
  </div>
</template>

<script>
import TheFooter from "./components/commonComponents/TheFooter";
import TheHeader from "./components/commonComponents/TheHeader";
import LoadingIndicator from "./components/LoadingIndicator";
import ErrorWindow from "./components/BaseErrorWindow";
import LoginErrorWindow from "./components/BaseErrorWindow/ErrorLoginWindow.vue";
import LoginCarrierErrorWindow from "./components/BaseErrorWindow/ErrorLoginCarrierWindow.vue";

import axios from "axios";
import { mapActions } from "vuex";
export default {
  components: {
    TheFooter,
    TheHeader,
    LoadingIndicator,
    ErrorWindow,
    LoginErrorWindow,
    LoginCarrierErrorWindow,
  },
  methods: {
    ...mapActions({
      setSchools: "setSchools",
      setRegionsList: "setRegionsList",
      setStatusesList: "setStatusesList",
      setUniversitiesList: "setUniversitiesList",
      setPrivilegeDocumentTypes: "setPrivilegeDocumentTypes",
    }),
    async getMetadata() {
      let resp = await this.apiGetAllMetadata();
      if (
        resp == null ||
        resp.statuses.length == 0 ||
        resp.schools.length == 0 ||
        resp.universities.length == 0 ||
        resp.privileges.length == 0 ||
        resp.regions.length == 0
      ) {
        this.$store.commit("toggleErrorWindow", true);
        return;
      }
      this.setStatusesList(resp.statuses);
      this.setSchools(resp.schools);
      this.setUniversitiesList(resp.universities);
      this.setPrivilegeDocumentTypes(resp.privileges);
      this.setRegionsList(resp.regions);
    },
    async apiGetAllMetadata() {
      try {
        const resp = await axios.get(process.env.VUE_APP_APIURL + "metadata");
        return resp.data;
      } catch (error) {
        console.log(error);
        return null;
      }
    },
  },
  async created() {
    await this.getMetadata();
  },
};
</script>

<style lang="scss" src="./commonStyles/fonts.scss"></style>
<style lang="scss" src="./commonStyles/styles.scss"></style>
<style lang="scss">
</style>
