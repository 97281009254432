var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-payment__wrap"},[_c('div',{staticClass:"card-payment"},[_vm._m(0),_c('div',{staticClass:"card-payment__content"},[_c('div',{staticClass:"statement-number"},[_c('div',{staticClass:"statement-number-input"},[_c('p',[_vm._v("Введіть номер заяви")]),_c('input',{directives:[{name:"mask",rawName:"v-mask",value:('######'),expression:"'######'"}],attrs:{"type":"text","placeholder":"123456"}})]),_vm._m(1)]),_vm._m(2),_vm._m(3),_c('div',{staticClass:"payment"},[_c('h4',[_vm._v(" Оплатіть: ")]),_vm._m(4),_vm._m(5),_c('hr'),_vm._m(6),_c('div',{staticClass:"card-number"},[_c('p',[_vm._v(" Введіть номер банківської картки ")]),_c('input',{directives:[{name:"mask",rawName:"v-mask",value:('####-####-####-####'),expression:"'####-####-####-####'"}],attrs:{"type":"text","placeholder":"____-____-____-____"}})]),_c('div',{staticClass:"card-info"},[_c('div',{staticClass:"card-term"},[_c('p',[_vm._v(" Термін дії картки ")]),_c('input',{directives:[{name:"mask",rawName:"v-mask",value:('##/##'),expression:"'##/##'"}],attrs:{"type":"text","placeholder":"__/__"}})]),_c('div',{staticClass:"cvv"},[_c('p',[_vm._v(" CVV ")]),_c('input',{directives:[{name:"mask",rawName:"v-mask",value:('###'),expression:"'###'"}],attrs:{"type":"text","placeholder":"___"}})])])])]),_vm._m(7),_vm._m(8)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"card-payment-header"},[_vm._v(" Оплатіть замовлення"),_c('br'),_vm._v(" муніципальної картки вінничанина ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image-preview"},[_c('img',{attrs:{"src":require("../../../assets/PaymentPreview.png"),"alt":"Card payment"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"name"},[_c('h2',[_vm._v(" С***на Марія Іванівна ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"attention"},[_c('p',[_vm._v(" Увага! Достовірність внесених в заяві даних підтверджується замовником картки (або представником) у спосіб здійснення оплати. У випадку виявлення розбіжностей або помилок випущена картка буде знищена без повернення оплати! ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"payment-props"},[_c('h4',[_vm._v(" За картку: ")]),_c('div',{staticClass:"price"},[_c('p',[_vm._v(" 60 грн. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"payment-props"},[_c('h4',[_vm._v(" Комісія: ")]),_c('div',{staticClass:"price"},[_c('p',[_vm._v(" 1 грн. 38 коп. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"payment-props total-sum"},[_c('h4',[_vm._v(" Разом: ")]),_c('div',{staticClass:"price"},[_c('p',[_vm._v(" 61 грн. 38 коп. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auto-payment-info"},[_c('p',[_vm._v(" Зверніть увагу! Оплату буде зараховано в автоматичному режимі. Коли карту буде виготовлено, Вам надійде SMS та лист на електронну пошту. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"control-buttons"},[_c('button',{staticClass:"cancel-btn"},[_vm._v(" Скасувати ")]),_c('button',{staticClass:"pay-now-btn"},[_vm._v(" Оплатити зараз ")])])
}]

export { render, staticRenderFns }