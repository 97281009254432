var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group base-input"},[_c('label',{class:[_vm.validation && _vm.validation.required && _vm.text ? 'asterisk' : ''],attrs:{"for":_vm.reference}},[_vm._v(_vm._s(_vm.text))]),(_vm.mask)?_c('input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"},{name:"validate",rawName:"v-validate",value:(_vm.validation),expression:"validation"}],ref:_vm.reference,class:[
        'form-control',
        _vm.validation && _vm.validation.required ? 'required-field' : '',
      ],attrs:{"type":"text","id":_vm.reference,"placeholder":_vm.placeholder,"name":_vm.reference,"readonly":_vm.readonly,"disabled":_vm.disabled},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.value)}}}):_c('input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.validation),expression:"validation"}],ref:_vm.reference,class:[
        'form-control',
        _vm.validation && _vm.validation.required ? 'required-field' : '',
      ],attrs:{"type":"text","id":_vm.reference,"placeholder":_vm.placeholder,"name":_vm.reference,"readonly":_vm.readonly,"disabled":_vm.disabled},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.value)}}}),(_vm.isShownHint)?_c('p',{staticClass:"hints"},[_vm._v(_vm._s(_vm.hint))]):_vm._e(),_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(_vm.errors.first(_vm.reference) ? _vm.customErrorMessage ? _vm.customErrorMessage : _vm.errors.first(_vm.reference) : ""))])])
}
var staticRenderFns = []

export { render, staticRenderFns }