<template>
  <div class="container">
    <div class="row">
      <div class="content">
        <page-header>ЦЕНТРИ ОБСЛУГОВУВАННЯ</page-header>
        <!--<h2>для оформлення персоніфікованої картки</h2>-->

           <div class="servise_message-block">
                      Шановні відвідувачі! <br>
                      З Видача муніципальних карток вінничанина здійснюється тільки за адресою: м. Вінниця, вул. Соборна, 36.

                        </div>
                
         <div class="service">
           <p class="text-bold">
            <span>КП «Вінницькі інформаційний центр»</span> - м. Вінниця, вул. Соборна, 36
          </p>
          <p>У центрі здійснюється обслуговування юридичних осіб. 
              </p>
                          <p>
                Для фізичних осіб здійснюється:
                <ul class="sublist">
                  <li>
                    - видача карток;
                  </li>
                    <li>
                      - оформлення заяв на виготовлення карток;
                    </li>
                    <li>
                      - прийом заяв на блокування карток;
                    </li>
                    <li>
                      - прийом заяв на повторне виготовлення карток;
                    </li>
                      - прийом карток на ремонт;
                    <li>
                    - заміна типу карток (з учнівської на студентську, із студентської на загальну, із загальної на пільгову тощо);
                  </li>
                  <li>- консультування.

                  </li>
                </ul>
                </p>

          <p class="text-bold">Графік роботи:</p>
          <ul class="sublist">
            <li>понеділок, вівторок з 9.00-17.00 без перерви;</li>
            <li> <b>середа з 9.00-19.00 без перерви</b>;</li>
            <li>четвер, п'ятниця з 9.00-17.00 без перерви;</li>
            <li>субота, неділя - вихідні.</li>

            <li class="li-icon">
              <span class="icon-telephone-receiver"></span
              ><a href="tel:0800330155">(0800)-330-155</a>
            </li>
            <li class="li-icon">
              <span class="icon-telephone-receiver"></span>
              <a href="tel:+380432655155">(0432)-655-155</a>
            </li>
          </ul>
          </div>
        <hr class="line" />

        <div>
          <p class="text-bold">
            <span>Центр надання адміністративних послуг "Прозорий офіс" (відділення "Вишенька") </span> – м.
            Вінниця, проспект Космонавтів, 30
          </p>

          <p>У центрі здійснюється обслуговування фізичних осіб:
              <ul class="sublist">
                <li>
                    - оформлення заяв на виготовлення карток;
                </li>
                    <li>
                    - прийом заяв на блокування карток;
                    </li>
                    <li>
                    - прийом заяв на повторне виготовлення карток;
                    </li>
                    <li>
                    - консультування.
                  </li>
                </ul>
                </p>

          <p class="text-bold">Графік роботи:</p>
          <ul class="sublist">
            <li><li>вівторок, четвер з 9.00 - 16.00 без перерви</li></li>
            <li class="li-icon">
              <span class="icon-telephone-receiver"></span>
              <a href="tel:0971015840">(097)101-58-40</a>
            </li>
            <li class="li-icon">
              <span class="icon-telephone-receiver"></span>
              <a href="tel:0638566272">(063)856-62-72</a>
            </li>
            <li class="li-icon">
              <span class="icon-telephone-receiver"></span>
              <a href="tel:+380432599046">(0432) 50-83-88</a>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "../commonComponents/PageHeader";

export default {
  components: {
    PageHeader,
  },
};
</script>

<style scoped lang="scss" src="./style.scss"></style>