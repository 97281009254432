<template>
  <div class="container">
    <div class="row">
      <div class="content">
        <page-header>НАВІГАЦІЯ БОРТІВ (ТЕСТУВАННЯ)</page-header>
        <h2>Cистема спостереження за рухом громадського транспорту Online</h2>
        <a href="https://map.et.vn.ua/"
          >Система візуалізації руху транспорту
        </a>
        на карті міста знаходиться на стадії тестування.
      </div>
    </div>
  </div>
</template>


<script>
import PageHeader from "../components/commonComponents/PageHeader";

export default {
  components: {
    PageHeader,
  },
};
</script>

<style scoped lang="scss">
a {
  color: #c33d83;
}

h2 {
  margin-bottom: 20px;
}
</style>