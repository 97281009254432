export default {
  state: {
    isErrorWindowShown: false,
    isErrorLoginWindow: false,
    isErrorLoginCarrierWindow: false,
    blockMinutes: 0,
  },
  getters: {
    isErrorWindowShown(state) {
      return state.isErrorWindowShown;
    },
    isErrorLoginWindow(state) {
      return state.isErrorLoginWindow;
    },
    blockMinutes(state) {
      return state.blockMinutes;
    },
    isErrorLoginCarrierWindow(state) {
      return state.isErrorLoginCarrierWindow
    }
  },
  mutations: {
    toggleErrorWindow(state, payload = false) {
      state.isErrorWindowShown = payload || !state.isErrorWindowShown;
    },
    toggleErrorLoginWindow(state, payload = false) {
      state.isErrorLoginWindow = payload || !state.isErrorLoginWindow;
    },
    toggleErrorLoginCarrierWindow(state, payload = false) {
      state.isErrorLoginCarrierWindow = payload || !state.isErrorLoginCarrierWindow;
    },
    setBlockMinutes(state, payload = 0) {
      state.blockMinutes = payload;
    }
  },
};
