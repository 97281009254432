<template>
  <div class="camera-wrapper">
    <video
      src=""
      id="selfie-camera"
      :style="{ width: this.width, height: this.height }"
    />
    <button
      :loading="isSnapshoting"
      @click="makeSnapshot"
      class="mx-2 snapshot-btn"
      fab
      dark
      small
      color="primary"
    >
      <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
        <path
          fill="#B54285"
          d="M4,4H7L9,2H15L17,4H20A2,2 0 0,1 22,6V18A2,2 0 0,1 20,20H4A2,2 0 0,1 2,18V6A2,2 0 0,1 4,4M12,7A5,5 0 0,0 7,12A5,5 0 0,0 12,17A5,5 0 0,0 17,12A5,5 0 0,0 12,7M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9Z"
        />
      </svg>
    </button>
    <button
      @click="flipCamera"
      class="camera-btn"
      fab
      dark
      small
      color="primary"
    >
      <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
        <path
          fill="#B54285"
          d="M20 5H17L15 3H9L7 5H4C2.9 5 2 5.9 2 7V19C2 20.11 2.9 21 4 21H20C21.11 21 22 20.11 22 19V7C22 5.9 21.11 5 20 5M5 12H7.1C7.65 9.29 10.29 7.55 13 8.1C13.76 8.25 14.43 8.59 15 9L13.56 10.45C13.11 10.17 12.58 10 12 10C10.74 10 9.6 10.8 9.18 12H11L8 15L5 12M16.91 14C16.36 16.71 13.72 18.45 11 17.9C10.25 17.74 9.58 17.41 9 17L10.44 15.55C10.9 15.83 11.43 16 12 16C13.27 16 14.41 15.2 14.83 14H13L16 11L19 14H16.91Z"
        />
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  name: "Camera",
  props: {
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      streamWidth: null,
      streamHeight: null,
      camerasList: [],
      currentCameraIndex: 0,
      stream: {},
      video: {},
      isSnapshoting: false,
    };
  },
  async created() {
    let devices = await navigator.mediaDevices.enumerateDevices();
    this.camerasList = devices.filter((x) => {
      return x.kind == "videoinput";
    });
    this.openCamera();
  },
  beforeDestroy() {
    this.closeCamera();
  },
  methods: {
    flipCamera() {
      this.closeCamera();
      let countCameras = this.camerasList.length;
      if (countCameras - 1 === this.currentCameraIndex) {
        this.currentCameraIndex = 0;
      } else {
        this.currentCameraIndex++;
      }
      this.openCamera();
    },
    async openCamera() {
      try {
        let currentCameraId =
          this.camerasList[this.currentCameraIndex].deviceId;
        this.stream = await navigator.mediaDevices.getUserMedia({
          video: {
            deviceId: currentCameraId,
            width: { min: 640, ideal: 1280, max: 1920 },
            height: { min: 480, ideal: 720, max: 1080 },
          },
        });
        this.video = document.querySelector("#selfie-camera");
        this.video.srcObject = this.stream;
        this.video.onloadedmetadata = (e) => {
          this.video.play();
        };
        let streamSettings = this.stream.getVideoTracks()[0].getSettings();
        this.streamWidth = streamSettings.width;
        this.streamHeight = streamSettings.height;
      } catch (error) {
        this.$emit("onError", error);
      }
    },
    makeSnapshot() {
      this.isSnapshoting = true;
      let canvas = document.createElement("canvas");
      canvas.width = this.streamWidth;
      canvas.height = this.streamHeight;
      let ctx = canvas.getContext("2d");
      ctx.drawImage(this.video, 0, 0, canvas.width, canvas.height);
      let dataURI = canvas.toDataURL("image/jpeg");
      this.$emit("onSnapshot", dataURI);
    },
    closeCamera() {
      try {
        this.stream.getTracks().forEach((track) => {
          track.stop();
        });
      } catch (error) {}
    },
  },
};
</script>

<style lang="scss" scoped>
.camera-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  .snapshot-btn {
    position: absolute;
    bottom: 15px;
    background-color: #f7f7f7;
    outline: none !important;
    border-radius: 50%;
    border-color: transparent;
    box-shadow: none;
  }
  .camera-btn {
    position: absolute;
    bottom: 15px;
    right: 30px;
    background-color: #f7f7f7;
    outline: none !important;
    border-radius: 50%;
    box-shadow: none;
    border-color: transparent;
  }
}
</style>